.page {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: background-color 1s ease;
  z-index: 1;
  /* Ensure content is on top */
}

.page.transition-bg {
  background-color: #FFCB00;
}

.page::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 200%;
  height: 200%;
  background-color: #FFCB00;
  border-radius: 50%;
  transform: translateX(-50%) translateY(100%);
  transition: transform 2s ease;
  z-index: -1;
  /* Ensure background transition is behind content */
}

.page.transition-bg::before {
  transform: translateX(-50%) translateY(0);
}


.container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #000;
  margin: 0 10px;
}

.content {
  padding: 0 10px;
  font-weight: bold;
}