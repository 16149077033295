/* *{
  background-color: #dee0df;
} */

body::-webkit-scrollbar {
  display: none;
}

.abcd::-webkit-scrollbar {
  display: none;
}

body {
  overflow-x: hidden;
}

.feeds::-webkit-scrollbar {
  display: none;
}

.lefttt {
  left: 0;
}

.gl {
  font-family: Figtree-Light !important;
}

.gx {
  font-family: Figtree-ExtraBold !important;
  /* font-weight: 800 !important; */
}

.gb {
  font-family: Figtree-Bold !important;
}

.blw {
  font-family: Balham !important;
}

.gsb {
  font-family: Figtree-SemiBold !important;
}

.cp {
  cursor: pointer !important;
}

.bw {
  background-color: #fff !important;
}

.bgdark {
  background-color: #000 !important;
}

a {
  text-decoration: none !important;
  /* color: black; */
}

.link {
  color: #000;
}

.link:hover {
  color: #000;
}

.link2 {
  color: #000;
}

.link2:hover {
  color: #000;
}

.textgrey {
  color: #6f6f6f;
}

.bggrey {
  background-color: #f7f7f7 !important;
}

.fc {
  height: fit-content !important;
}

.ssh {
  overflow: hidden;
}

.classTitleX {
  color: #5f5d5d;
  font-size: 5vw !important;
}

/* Home */

.page {
  text-align: center !important;
  min-height: 100vh !important;
  max-height: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.first {
  /* font-size: 1.5rem; */
  font-size: clamp(1rem, 2vw, 2.5rem);
  font-weight: 300;
}

.enterpara {
  /* font-size: 2.7rem; */
  font-size: clamp(1.6rem, 2.5vw, 6rem);
  line-height: 1;
  /* font-weight: 900 !important; */
  margin-bottom: 6rem;
  color: black !important;
}

.bt {
  background-color: transparent !important;
}

.underline {
  display: inline-block;
  position: relative;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffcc00;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Header  */

nav {
  /* background-color: #6666666e  !important; */
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: #ffffff !important;
}

nav.active {
  color: #000000 !important;
  /* border-bottom: #000 solid 1px !important; */
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
}

/* .navbar{
  background-color: transparent !important;
} */

.mainpage {
  min-height: 100vh;
}

/* 
ul{
  content: "";
} */

.navul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin-bottom: 0 !important;
  /* background-color: transparent !important; */
}

/* li{
  background-color: transparent !important;
} */

.navs {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* padding: 1rem; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Carousel */

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  visibility: hidden;
}

.carousel-caption h5 {
  background-color: transparent !important;
}

.carousel-caption p {
  background-color: transparent !important;
}

.carousel-indicators {
  background-color: transparent !important;
}

.carousel-control-prev {
  width: 4% !important;
}

/* Carousel 1 */

@media (min-width: 885px) {
  .c1 {
    background-color: transparent !important;
    top: 30%;
    transform: translateX(-10%);
    text-align: left !important;
  }
}

@media (max-width: 885px) {
  .c1 {
    background-color: transparent !important;
    top: 20% !important;

    transform: translateX(-10%);
    text-align: left !important;
  }
}

.c1 h5 {
  color: #000 !important;
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 4vw, 4rem);
  line-height: 1;
  right: 20% !important;
}

.c1 button {
  background-color: #000 !important;
  opacity: 0.5;
  color: #fff !important;
  border: none !important;
  padding: 1rem 2rem !important;
  /* font-size: 1.2rem !important; */
  margin-top: clamp(1rem, 3vw, 10rem) !important;
  font-size: clamp(0.4rem, 2vw, 1.8rem) !important;
  font-family: Gilroy-Light;
  border-radius: 30px !important;
  cursor: pointer !important;
  margin-top: 3rem;
}

.c1 button:hover {
  opacity: 0.6;
  background-color: #ffcc00 !important;
}

/* Carousel 2 */

@media (min-width: 885px) {
  .c2 {
    background-color: transparent !important;
    top: 25%;
    transform: translateX(-15%);
    text-align: left !important;
  }

  .navs2 {
    visibility: hidden !important;
  }
}

@media (max-width: 885px) {
  .c2 {
    background-color: transparent !important;
    top: 20% !important;

    transform: translateX(-10%);
    text-align: left !important;
  }

  .c2 h5 {
    color: #fff !important;
  }

  .c2 h6 {
    color: #fff !important;
  }

  nav {
    visibility: hidden;
  }

  .navs2 {
    visibility: visible !important;
  }
}

.c2 h5 {
  color: #000;
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 4vw, 3.8rem);
  line-height: 1;
  right: 20% !important;
}

.c2 h6 {
  color: #000;
  font-family: Gilroy-Light;
  font-size: clamp(0.6rem, 3vw, 1.8rem);
  font-weight: 1000 !important;
  margin-top: clamp(1rem, 3vw, 10rem) !important;
  /* font-size: clamp(0.4rem, 2vw, 1.8rem) !important; */
  line-height: 1;
  right: 20% !important;
  background-color: transparent !important;
}

.c2 button {
  background: rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
  border: none !important;
  padding: 1rem 2rem !important;
  font-size: 1.2rem !important;
  font-family: Gilroy-Light;
  border-radius: 30px !important;
  cursor: pointer !important;
  margin-top: clamp(0.3rem, 3vw, 10rem) !important;
  font-size: clamp(0.4rem, 1vw, 1.8rem) !important;
}

.c2 button:hover {
  background-color: hsl(48, 100%, 50%, 0.6) !important;
}

/* Carousel 3 */

@media (min-width: 885px) {
  .c3 {
    background-color: transparent !important;
    top: 40%;
    transform: translateX(-5%);
    text-align: left !important;
  }
}

@media (max-width: 885px) {
  .c3 {
    background-color: transparent !important;
    top: 20% !important;

    transform: translateX(-10%);
    text-align: left !important;
  }

  nav {
    visibility: hidden;
  }
}

.c3 h5 {
  color: rgb(255, 255, 255) !important;
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 4vw, 3.6rem);
  line-height: 0.8;
  right: 20% !important;
}

.c3 button {
  background-color: rgba(255, 255, 255, 0.6) !important;
  color: #000 !important;
  border: none !important;
  padding: 0.7rem 4rem !important;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  font-family: Gilroy-Light;
  border-radius: 30px !important;
  cursor: pointer !important;
  margin-top: clamp(1rem, 3vw, 10rem) !important;
  /* font-size: clamp(0.4rem, 2vw, 1.8rem) !important; */
}

.c3 button:hover {
  background-color: hsl(48, 100%, 50%, 0.6) !important;
}

.c3 p {
  color: #fff !important;
  font-family: Gilroy-Light;
  font-size: clamp(0.6rem, 2.5vw, 1.8rem);
  font-weight: 1000 !important;
  margin-top: 2rem !important;
  line-height: 1;
  background-color: transparent !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* .under{
  display: inline-block;
  position: relative;
  font-size: 1.1rem !important;
} */

.under::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffcc00;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.under:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Carousel 4 */

@media (min-width: 885px) {
  .c4 {
    background-color: transparent !important;
    top: 40%;
    transform: translateX(-10%);
    text-align: left !important;
  }
}

@media (max-width: 885px) {
  .c4 {
    background-color: transparent !important;
    top: 30% !important;

    transform: translateX(-10%);
    text-align: left !important;
  }

  nav {
    visibility: hidden;
  }
}

.c4 h5 {
  color: rgb(255, 255, 255) !important;
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 4vw, 3.6rem);
  line-height: 0.8;
  right: 20% !important;
}

.c4 button {
  background-color: rgba(255, 255, 255, 0.6) !important;
  color: #000 !important;
  border: none !important;
  padding: 1rem 2rem !important;
  font-size: 1.2rem !important;
  font-family: Gilroy-Light;
  border-radius: 30px !important;
  cursor: pointer !important;
  margin-top: clamp(0.3rem, 3vw, 10rem) !important;
  font-size: clamp(0.4rem, 1vw, 1.8rem) !important;
}

.c4 button:hover {
  background-color: hsl(48, 100%, 50%, 0.6) !important;
}

.classescontainer {
  background-color: #f7f7f7 !important;
}

.classesimg {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.profileclassesimg22 {
  height: 30vw !important;
}

.classesimg22 {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  /* margin-bottom: 10rem; */
  border-radius: 15px 15px 0 0;
}


.hoverlater {
  height: 180px !important;
  transition: all 0.5s ease-in-out !important;
  cursor: pointer !important;
}

.hoverlater:hover {
  height: 400px !important;
  transition: all 0.3s !important;
  transform: scale(1.2) !important;
  transition-delay: 1.5s !important;
}

/* .hoverlater:hover > .shush{
  display: block !important;
  transition: all 0.3s !important;
  transition-delay: 2s  !important;
} */

.imgdiv {
  height: 250px !important;
}

.imgdiv2 {
  height: 250px !important;
}

.clsdesc {
  font-size: clamp(0.6rem, 2.5vw, 0.8rem);
  font-family: Gilroy-Light !important;
  color: rgb(61, 61, 61) !important;
  min-height: 30px !important;
}

.knowdiv {
  background-color: #fff !important;
  border-radius: 40px !important;
  border: rgb(57, 57, 57) solid 1px !important;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0.9rem;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  color: rgb(57, 57, 57) !important;
}

.knowdiv input {
  background-color: transparent !important;
}

.borderless {
  border: none !important;
  /* min-width: 300px; */
  margin-left: 10px;
}

.borderless:focus {
  outline: none !important;
  border: 2px solid #2c71b6;
  /* box-shadow: 0 0 1px #2c71b6; */
}

.profilesearch {
  color: rgb(166, 174, 0) !important;
  font-weight: 800;
}

.icondiv {
  background-color: rgb(39, 39, 39) !important;
  border-radius: 50% !important;
  cursor: pointer;
}

.clsfee h6 {
  font-size: clamp(0.6rem, 2.2vw, 1.1rem) !important;
  font-family: Gilroy-ExtraBold !important;
  color: rgb(61, 61, 61) !important;
  line-height: 0.9;
}

.profileclassesimg {
  height: 250px !important;
  /* width: 114px !important; */
}

.classesicon {
  width: 100% !important;
  object-fit: cover;
  border-radius: 50%;
}

.bgw {
  background-color: #fff !important;
}

.profile {
  background-color: #fff !important;
  border-radius: 0 0 15px 15px;
  display: flex;
  min-height: 216px !important;
  /* justify-content: space-around; */
}

.classtitlee {
  font-size: clamp(0.9rem, 4vw, 1.2rem);
  font-family: Gilroy-ExtraBold !important;
  color: rgb(85, 85, 85) !important;
  line-height: 1;
}

.tutorname {
  font-size: clamp(0.6rem, 3vw, 1rem);
  font-family: Gilroy-ExtraBold !important;
  color: rgb(61, 61, 61) !important;
  line-height: 0.1;
}

.profilest {
  background-color: #fff !important;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-around;
  height: fit-content !important;
}

.profilest h6 {
  font-family: Gilroy-ExtraBold;
  font-size: clamp(0.1rem, 4vw, 0.6rem);
  /* line-height: 0.8; */
  color: #000 !important;
}

@keyframes ldio-cqj9sf9mcdj {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-cqj9sf9mcdj div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-cqj9sf9mcdj div {
  animation: ldio-cqj9sf9mcdj 1s linear infinite;
  top: 50px;
  left: 50px;
}

.loadingio-spinner-rolling-jm01qv7mmak {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-cqj9sf9mcdj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-cqj9sf9mcdj div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */

.classcol {
  width: 380px !important;
}

.scroll-container {
  overflow: auto;
  white-space: nowrap;
  padding: 5px 70px 5px 20px;
  background: transparent;
  height: 100%;
  border-radius: 15px;
}

.gridscroll {
  display: inline-block;
}

.profilest p {
  font-family: Gilroy-Light;
  font-size: clamp(0.5rem, 5vw, 0.9rem);
  /* line-height: 0.8; */
  color: #000;
}

.profile p {
  font-family: Gilroy-Light;
  font-weight: 900;
  font-size: clamp(0.3rem, 3vw, 0.8rem);
}

.seemore {
  background-color: transparent !important;
  border-radius: 15px;
  border: rgb(57, 57, 57) solid 1px;
  display: flex;
  justify-content: space-around;
  padding: 0.4rem 3rem;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  color: rgb(57, 57, 57) !important;
}

.seemore:hover {
  background-color: rgb(36, 36, 36) !important;
  color: #ffcc00 !important;
}

/* Login Page  */

.loginpage {
  background-color: #fff !important;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
}

.input {
  background-color: #fff !important;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  padding: 0.6rem 0.8rem;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  color: rgb(57, 57, 57) !important;
}

.logindiv {
  background-color: #fff !important;
  min-width: 300px !important;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  color: rgb(57, 57, 57) !important;
}

.signupform {
  max-width: 600px !important;
}

/* input {
  text-align: center;
} */

/* ::-webkit-input-placeholder {
  text-align: center;
} */

/* :-moz-placeholder {
  text-align: center;
} */

/* Footer  */

.footerrow {
  background-color: #f7f7f7 !important;
  padding-top: 4vw !important;
}

.footercol {
  /* margin-top: 70px !important; */
  margin-bottom: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.contactmail {
  font-family: Gilroy-Light;
  font-size: clamp(0.4rem, 2vw, 1.6rem) !important;
  color: rgb(57, 57, 57) !important;
}

.footercol p {
  font-weight: 400;
}

.moto {
  /* font-family: Gilroy-Light; */
  font-size: clamp(0.9rem, 4vw, 1.3rem) !important;
  color: rgb(57, 57, 57) !important;
}

.title {
  /* font-family: Gilroy-ExtraBold; */
  /* font-size: clamp(1.5rem, 7vw, 2.5rem) !important; */
  /* color: rgb(57, 57, 57) !important; */
  font-size: 1.5vw !important;
  margin-right: 4vw;
}

.desc {
  /* font-family: Gilroy-Light; */
  /* font-size: clamp(0.9rem, 3vw, 1.2rem) !important; */
  font-size: 1.2vw !important;
  /* padding-top: 0.3vw; */
  margin-bottom: 0.4vw !important;
  /* color: rgb(57, 57, 57) !important; */
}

.reg_div {
  /* min-height: 150px !important; */
  background-color: #f7f7f7 !important;
  margin-bottom: 50px !important;
  margin-top: 100px;
  max-height: fit-content !important;
}

.vertical-center {
  margin: 0;
  /* position: absolute; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.regtitle {
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 6vw, 4rem) !important;
  opacity: 0.9;
  color: rgb(57, 57, 57) !important;
  margin-top: 5vw;
}

.noti-content {
  padding: 12px;
  border-radius: 8px !important;
  background-color: var(--white) !important;
  color: var(--dark);
}

.noti-content:focus {
  outline: none !important;
  border: 2px solid #2c71b6;
  /* box-shadow: 0 0 1px #2c71b6; */
}

.react-tel-input .form-control {
  z-index: 999 !important;
  /* min-width: 200px !important; */
  /* position: absolute !important; */
  display: block !important;
  /* max-width: 278px !important; */
  max-width: 100% !important;
  min-width: 100% !important;
}

.joincontainer {
  max-width: 1100px !important;
}

.country-list {
  z-index: 999 !important;
  width: 300px !important;
  /* position: absolute !important; */
  display: block !important;
  position: absolute !important;
  background-color: #ffffff !important;
}

.profilediv {
  margin-top: 200px !important;
  min-height: 300px !important;
  background-color: #f7f7f7 !important;
  /* max-height: fit-content !important; */
  /* background-color: #f7f7f7 !important; */
}

.white {
  height: 12vh !important;
}

.searchdiv {
  background-color: #fff !important;
  border-radius: 40px !important;
  /* border: rgb(57, 57, 57) solid 1px !important; */
  box-shadow: 0 0 0.5rem #49444446;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0.5rem;
  font-size: clamp(0.4rem, 2vw, 1rem) !important;
  color: rgb(57, 57, 57) !important;
  position: relative;
}

.borderlessinput {
  border: none !important;
  /* min-width: 300px; */
  margin-left: 10px;
  background-color: transparent !important;
}

.borderlessinput:focus {
  outline: none !important;
  border: none !important;
  box-shadow: 0 0 1px #3f3f3f;
}

.profilesearch {
  color: rgb(166, 174, 0) !important;
  font-weight: 800;
}

.icondiv {
  background-color: rgb(39, 39, 39) !important;
  border-radius: 50% !important;
  cursor: pointer;
}

.timer h1 {
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 6vw, 4rem) !important;
  /* color: rgb(181, 175, 0) !important; */
  color: var(--yellow) !important;
  line-height: 0.6;
}

/* Join page  */

.classtitle {
  font-family: Gilroy-ExtraBold;
  font-size: clamp(1.5rem, 7vw, 3.5rem) !important;
  color: rgb(94, 94, 94) !important;
}

.classduration {
  font-weight: 700;
}

.hostdiv {
  position: absolute !important;
  bottom: 0 !important;
}

.joinclassdiv {
  width: 100% !important;
  border-radius: 30px !important;
  border: #000 solid 1px !important;
  min-height: 200px !important;
  max-height: fit-content !important;
}

.of_cover {
  object-fit: cover !important;
}

.classimage {
  width: 200px;
  /* width of container */
  height: 300px;
  /* height of container */
  object-fit: cover;
  /* border: 3px solid black; */
  border-radius: 20px;
  object-position: 20% 10%;
  /* try 20px 10px */
  /* -webkit-filter: grayscale(100%);  */
  /* filter: grayscale(100%);  */
}

.classimages {
  width: 100%;
  /* height: 400px;  */
  height: 100%;
  object-fit: cover;
  /* border: 3px solid black; */
  border-radius: 20px;
  object-position: 20% 10%;
  /* try 20px 10px */
}

.iconpic {
  width: 280px;
  object-fit: cover;
  /* border: 3px solid black; */
  /* border-radius: 20px; */
  /* object-position: 20% 10%; try 20px 10px */
  /* -webkit-filter: grayscale(100%);  */
  /* filter: grayscale(100%);  */
  margin-bottom: -180px !important;
}

@media (max-width: 768px) {
  .icon1 {
    display: block !important;
  }

  .icon2 {
    display: none !important;
  }
}

/* a{
  color: var(--dark) !important;
} */

@media (min-width: 768px) {
  .icon1 {
    display: none !important;
  }

  .icon2 {
    display: block !important;
  }
}

.MuiFormHelperText-root {
  /* font-size: clamp(0.4rem, 2vw, 1rem) !important; */
  color: rgb(57, 57, 57) !important;
}

.rmdp-input {
  height: 56px !important;
  width: 100% !important;
}

.timefield {
  border: #a1a1a1 solid 1px !important;
  height: 56px !important;
}

li {
  content: "";
}

.profilepic {
  /* Make background pulse so it looks kinda load-y */
  height: 120px !important;
  animation: skeleton-loader 0.8s infinite alternate;
}

.feeds {
  /* Make background pulse so it looks kinda load-y */
  height: 1500px !important;
  /* animation: skeleton-loader 0.8s infinite alternate; */
  /* overflow: hidden; */
  overflow-y: scroll !important;
}

.imgscroll::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

.abc::-webkit-scrollbar {
  display: none;
  /* width of the entire scrollbar */
}

.imgscroll::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.imgscroll::-webkit-scrollbar-thumb {
  background-color: var(--yellow);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
  /* creates padding around scroll thumb */
}

.imgdlt {
  visibility: none !important;
}

.imghover:hover {
  transform: scale(1.1) !important;
}

.imghover:hover+.imgdlt {
  visibility: visible;
}


@keyframes skeleton-loader {
  0% {
    background-color: hsl(200, 20%, 75%);
  }

  50% {
    background-color: hsl(200, 20%, 85%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton {
  animation: skeleton-loader 1s linear infinite alternate;
}

.skeleton-text {
  width: 30%;
  height: .9rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.skeleton-smalltext {
  width: 100%;
  height: .5rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.skeleton-smalltext:last-child {
  margin-bottom: .25rem;
  width: 80%;
}

.skeleton-box {
  width: 100%;
  height: 2rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}

.skeleton-id {
  width: 60%;
  height: .5rem;
  font-size: clamp(0.9rem, 4vw, 1.2rem);

  margin-bottom: .25rem;
  border-radius: .125rem;
}

.white-icon {
  color: rgb(0, 0, 0) !important;
  /* Set the icon color to white */
}

.larger-icon {
  font-size: 24px !important;
  /* Adjust the font size for the larger icon */
}


.wideer {
  width: 1000px !important
}

.overflow-ellipsis {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /*    Hide any overflowed content*/
  text-overflow: ellipsis;
  /* Display an ellipsis (...) when text overflows */
}

.info-text-title {
  font-size: 0.9vw !important;
  font-weight: 700 !important;
  color: #858585 !important;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /*    Hide any overflowed content*/
  text-overflow: ellipsis;
  /* Display an ellipsis (...) when text overflows */
  margin-left: 0.5vw !important;
}

.info-text-name {
  font-size: 0.7vw !important;
  color: #858585 !important;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /*    Hide any overflowed content*/
  text-overflow: ellipsis;
  /* Display an ellipsis (...) when text overflows */
  margin-left: 0.5vw !important;
}

.newClass {
  justify-content: left;
}

.bxsh {
  box-shadow: rgba(128, 128, 128, 0.815);
}

.cont-feed {
  margin-left: 3rem !important;
}

.w-150 {
  width: 150% !important;
}

.black {
  color: black !important;
  font-weight: 800;
}

.flowy {
  overflow-y: auto;
}

.container {
  max-width: 100% !important;
}

.scrolly {
  overflow-y: scroll;
  height: 66vw;
  overflow-x: hidden;
  width: 48vw !important;
}



/* // popup */

.popup-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.popup {
  max-width: 600px;
  /* Limiting the max-width to prevent it from becoming too wide on large screens */
  margin: auto;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  height: 70%;
  overflow-y: auto;
}

.popup-header {
  padding: 10px;
  text-align: right;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.popup-content {
  padding: 20px;
}

.classDiv {
  width: 20vw !important;
}

.classPic {
  width: 20vw !important;
}

.friClColumn {
  width: 22% !important;
  margin-left: 6vw;
  margin-right: 2vw;
}

.scrollyHeighty {
  height: 41vw;
  width: 46vw !important;
}

.heightExtender {
  height: 38vw;
  display: block;
}

.seemorebutton {
  margin-left: 40%;
  margin-top: 3vw !important;
}

.classDiv h2 {
  font-size: 2vw;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: 60%;
}

.popup-content {
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.comment-box-container {
  text-align: center;
  margin-top: 20px;
}

.checker3 {
  height: 6vw;
}

.hostDetailText {
  font-size: 1.5vw;
}

.hostDetailTextPar {
  font-size: 1.5vw;
  color: #606060;
  ;
}

.likeCommText {
  font-size: 1.5vw;
}

.profileInputAre {
  font-size: 2vw;
  width: 20vw;
}

.input-with-arrow {
  font-size: 1vw;
  display: flex;
  margin-top: 0.5vw;
  border: rgb(57, 57, 57) solid 0.5px !important;
  height: 2.5vw;
  border-radius: 0.5vw 0.5vw 0.5vw 0.5vw !important;
  overflow: hidden;
}

.input-with-arrow input {
  outline: none;
  width: 90%;
  border: none;
  padding-left: 0.8vw;
}

.buttonForInputPJX {
  outline: none;
  border: none;
  color: #ffcc00;
  font-size: 1.8vw;
  padding-top: 0;
  padding-bottom: 0vw;
  background-color: #000000;
}

.buttonForInputPJX svg {
  margin-bottom: 0.3vw;
}

.profileJoiner {
  width: 20vw;
  background-color: #000;
  color: #ffffff;
  font-size: 1.5vw;
  padding: 0.5vw;
  border-radius: 1vw;
  margin-bottom: 2vw;
}

.popup-content form {
  display: flex;
  width: 35vw;
  height: 3vw;
}

.popup-content form textarea {
  font-size: 1.5vw;
  font-family: Gilroy-Light;
  width: 80%;
  border: none;
  /* Remove default border */
  outline: none;
  /* Remove default outline */
  border: 1px solid #ccc;
  /* Add thin gray border */
  resize: none;
}

.popup-content form button {
  width: 20%;
  background-color: #ffcc00;
}

.class {
  /* width: 40%; */
  margin-right: 2vw;
}

.dfimg {
  width: 5vw;
  border-radius: 50%;
  height: 5vw;
  margin-left: 1vw;
  object-fit: cover;
}

.cDiv {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
  padding-top: 0.5vw;
}

.ctitle {
  color: #474646;
  font-size: 1.3vw;
  text-transform: uppercase;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Limit to 3 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cName {
  color: #616060;
  font-size: 1vw;
  text-transform: uppercase;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Limit to 3 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.w-85 {
  width: 85%;
}

.clr {
  margin-right: 3vw;
}

.mt-10 {
  margin-top: 7vw !important;
}

#liveText {
  color: white !important;
  background-color: red !important;
  margin-top: -30vw;
  margin-left: -14.5vw;
  width: 6vw;
  font-weight: 900;
  border-radius: 0.3vw;
  font-size: 1.5vw;

}

.imgInClass {
  width: 80vw;
  margin-left: 9vw;
  margin-top: 8vw;
  margin-bottom: 3vw;
}

.ongoinclass {
  background-color: #f7f7f7 !important;
}

.rating {
  margin-top: -3VW;
  width: 25%;
  margin-left: 64%;
}

.textRate {
  background-color: black;
  height: 3vw;
  color: white;
  text-align: center;
  padding-top: 0.5vw;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.streaming-form-box {
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  /* Adjust shadow values as needed */
  padding: 20px;
  border-radius: 8px;
  /* Optional: Add rounded corners */
  width: 80vw;
  /* Adjust width as needed */
  margin: 30px auto;
  /* Center the box horizontally */
  background-color: #f7f7f7;
  height: 33vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.5vw;
}

.streamButton {
  background-color: #ffcc00;
  color: black;
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  margin-left: 20%;
  height: 3vw;
  font-size: 2vw;
}

.lastrem {
  margin-right: 3vw;
  font-size: 1.5vw;

}

.StreamData {
  color: #858585;
  font-size: 4vw;
  text-align: center;
  height: 8vw;
  margin-top: 3vw;
  margin-bottom: 3vw;
}

.stlst {
  margin-top: 8vw;
  text-align: center;
}

.llii {
  width: 20vw;
}

.workTitle {
  margin-left: 8.5vw;
  margin-top: 0.5vw !important;
  font-size: 1.5vw;
}

/* /////////////////////////////////////////// */
.search-container {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 2vw;
  /* padding: 8px; */
  width: 70% !important;
  height: 4vw;
  margin-left: 4vw;
  border: 0.2vw solid #000000;
}

.search-input {
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.search-button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  /* padding: 0.4vw 0.8vw; */
  border-radius: 50%;
  background-color: #000;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  font-size: 2vw;
  margin-left: -0.7vw;
}

.yeicon {
  color: #ffcc00;
}

.clromHead {
  font-size: 6vw;
  color: #707070;
  word-spacing: 30px;
}

.rowkl {
  margin-top: -1vw !important;
  margin-bottom: 1vw !important;
}

.calIcon svg {
  width: 4vw !important;
  height: 5vw !important;
  margin-top: 1vw;
  margin-right: 1vw;
}

.timeClrom {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  font-size: 0.8vw !important;
}

.joinclrm {
  background-color: #000;
  color: #ffcc00;
  outline: none;
  border: none;
  border-radius: 0.5vw;
  margin-top: 0.5vw;
  font-size: 1vw;
  width: 8vw;
}

.brrrr {
  border: 1px solid black;
  border-radius: 1vw;
  overflow: hidden;
}

.reqquirey {
  margin-top: 8vw !important;
  margin-left: 2vw;
}

.clssss {
  margin-top: 5vw;
  margin-left: 2vw !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.llas {
  animation: scroll 10s linear infinite;
}

.h123 {
  height: 38vw;
}

.h134 {
  height: max-content;
}

.seemorebutton {
  border: #0000008a solid 1px;
  width: 15vw !important;
  text-align: center;
  border-radius: 2vw;
  height: 3vw;
  padding-top: 0.3vw;
  font-size: 1.5vw;
  color: #0000007a;
  cursor: pointer;
}

.alllll {
  position: absolute;
  left: 0vw;
  top: 8vw;
  color: white;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.13);
  padding: 1vw;
  cursor: pointer;
}

.arrrr {
  position: absolute;
  right: 11.8vw;
  top: 8vw;
  color: white;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  padding: 1vw;
  cursor: pointer;
  font-weight: 800;
  background-color: rgba(0, 0, 0, 0.13);
}

.sadjoasasjd {
  background-color: rgba(0, 0, 0, 0.384);
}

.elipiseee {
  border-radius: 1vw 1vw 0 0;
  display: flex;
  flex-direction: column;
  padding: 1vw;
}

.eliHead {
  font-size: 2vw;
  font-weight: 700;
  text-align: center;
}

.eliDesc {
  word-wrap: break-word;
  font-size: 1.5vw;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* Limit to 3 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.adminlink {
  margin-right: 4vw;
}

.classMeet {
  position: absolute;
  z-index: 1;
  width: 4vw;
  right: 1vw;
}

.opc0 {
  opacity: 0;
}

.firstDivAtJoinClass img {
  width: 20vw;
}

.secondDivAtJoinClass img {
  width: 7vw;
}

.ojoomax {
  display: flex;
  position: absolute;
  top: 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vw;
}

.myCallContainer2 {
  margin-top: 4vw;
}

.dnone {
  display: none;
}

.TFFfirst {
  background-image: url("https://res.cloudinary.com/black-box/image/upload/v1720084762/sn7ubyf3ejigkrfhwvss.jpg");
  width: 100%;
  height: 88vh;
  background-size: cover;
  /* This is equivalent to object-fit: cover for background images */
  background-position: top;
  /* Centers the image */
  background-repeat: no-repeat;
  margin-top: 6vw;
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: end;
  padding-right: 16vw;
  padding-top: 15%;
}

.TFFfirst .Text .faded {
  color: #171A1F8F;
  line-height: 4vw;
}

.TFFfirst .Text {
  font-size: 5.4vw;
  line-height: 5.4vw;
  width: 40vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 50%; */
}

.TFFfirst .Text .dark {
  height: fit-content;
  display: block;
}

.TFFfirst .Text .dark .darkText {
  color: black;
  margin-left: 1.4vw;
}

.jaosjdoasj {
  background-color: transparent !important;
  border: 1px solid #000 !important;
}

.BTteach {
  width: 60%;
  margin-right: 5% !important;
  margin-top: 1.5vw !important;
  font-size: 1.5vw !important;
  letter-spacing: -1px !important;
  line-height: 1.6vw !important;
  text-transform: none !important;
  box-shadow: none !important;
  padding: 0.5vw 1vw;
}

.joiningclassbuttons {
  padding-left: 5vw;
}


.TFFShead {
  text-align: center;
  font-size: 3.5vw;
  margin-top: 6vw;
  letter-spacing: -2px;
  line-height: 3.5vw;
}

.TFFSwhat {
  display: flex;
  margin-top: 2vw;
  justify-content: space-between;
  margin-left: 13vw;
  margin-right: 13vw;
}

.whatHead {
  font-size: 1.75vw;
  margin-bottom: 1vw;
  text-align: center;
  letter-spacing: -2px;
  line-height: 1.75vw;
}

.whatSpan {
  width: 21.5vw;
  font-size: 1.3vw;
  text-align: center;
  letter-spacing: -1px;
  line-height: 1.5vw;
}

.TFFTfirst {
  margin-bottom: 3vw;
  text-align: center;
  font-size: 3.5vw;
  margin-top: 6vw;
  letter-spacing: -2px;
  line-height: 3.5vw;
}

.TFFTstep {
  border: #FFCB00 solid 0.2vw;
  padding: 0vw 1vw;
  padding-bottom: 2vw;
}

.TFFTsteps {
  margin-left: 6vw;
  margin-right: 6vw;
  display: flex;
  gap: 2vw;
}

.stepS {
  font-size: 0.8vw;
  text-align: center;
  margin-top: 1vw;
  letter-spacing: 0px;
  line-height: 1vw;
}

.stepName {
  font-size: 1.8vw;
  text-align: center;
  margin-bottom: 1vw;
  line-height: 1.9vw;
  letter-spacing: -1px;
}

.stepDescr {
  font-size: 0.9vw;
  text-align: center;
  line-height: 1.1vw;
}

.TFFfourHead {
  width: 35%;
  margin-bottom: 3vw;
  text-align: center;
  font-size: 3.5vw;
  margin-top: 6vw;
  letter-spacing: -2px;
  line-height: 3.5vw;
}


.TFFfourth {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
}

.TFFfourQuestion {
  font-size: 1.3vw;
  line-height: 1.4vw;
  letter-spacing: -1px;
  width: 55%;
}

.QQ {
  margin-bottom: 0.8vw;
}

.QA {
  line-height: 2vw;
  padding-bottom: 1.5vw;
}

.Q1,
.Q2 {
  margin-bottom: 2vw;
  border-bottom: #0000001F solid 0.2vw;
}

.Testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tital {
  margin-bottom: 3vw;
  text-align: center;
  font-size: 3.5vw;
  margin-top: 6vw;
  letter-spacing: -2px;
  line-height: 3.5vw;
}

.titalImg img {
  width: 9vw;
  height: 9vw;
  object-fit: cover;
  border-radius: 50%;
}

.titalQuotes {
  font-size: 6vw;
  font-weight: 900;
}

.titalDes {
  width: 40%;
  font-style: italic;
  text-align: center;
  font-size: 1.65vw;
  margin-top: -4vw;
  margin-bottom: 1vw;
  letter-spacing: -1px;
  line-height: 1.9vw;

}

.titalName {
  color: #110000A3;
  font-size: 1vw;
}

.TFFsixth {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 50vh;
  background-color: #FFCB00;
  justify-content: center;
  align-items: center;
  margin-top: 10vw;
}

/* FFCB00 */
.TFFbannertitle {
  width: 25%;
  font-size: 3.5vw;
  text-align: center;
  line-height: 4vw;
  margin-bottom: 2vw;
  line-height: 3.5vw;
  letter-spacing: -1px;
}

.BTbanner {
  width: 15%;
  text-transform: none !important;
}

.jannetisGold {
  margin-left: 70%;
  gap: 2%;
}

.djdjdjdjdjdjdjdjdjdj {
  display: flex;
  flex-direction: column;
  gap: 3vw;
}

.loginBanner {
  background-color: #FFCB00;
  width: 70vw;
  font-size: 6vw;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  padding-left: 5%;
}

.loginBanner .faded {
  color: #171A1F8F;
  width: 30%;
  line-height: 5vw;
}

.optionInSignin {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.3vw solid black;
}

.fpassqord {
  font-size: 1vw;
}

.orinlogin::after {
  content: "OR";
  color: black !important;
  display: block;
  position: absolute;
  transform: translateX(815%) translateY(-40%);
  background-color: white;
  width: 2vw;
}

.questiondiv {
  display: flex;
}

.questionBanner {
  background-color: #FFCB00;
  width: 50vw;
  min-width: 50vw;
  height: 100vh;
}

.qbLogo img {
  width: 18vw;
  margin-top: 1.5vw;
  margin-left: 3vw;
}

.qbSpans {
  display: flex;
  flex-direction: column;
  /* width: 40%; */
  margin-left: 10vw;
  margin-top: 10vw;
  font-size: 5vw;
}

.qbSpans .faded {
  color: #171A1F8F;
  width: 28vw;
  line-height: 5vw;
  letter-spacing: -3px;
}

.qbSpans .bold {
  color: black;
  margin-top: -1.3vw;
  margin-bottom: -1.3vw;
  letter-spacing: -3px;
}

.Question {
  width: max-content;
  margin-left: 4vw;
  margin-top: 14vw;
}

.questionOfQma {
  font-size: 1.5vw;
  width: 75%;
}

.inputBoxOfQma {
  outline: none;
  border: none;
  border: 0.1vw solid #0E202A1F;
  width: 36vw;
  padding: 0.5vw 1vw;
  margin-top: 2vw;
}

.skipButtonInQuestion {
  position: absolute;
  top: 3vw;
  padding: 0.5vw 2vw;
  background-color: white;
  right: 1vw;
  font-size: 1vw;
}

.buttonsToskip {
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
}

.buttonsToskip span {
  font-size: 1vw;
}

.buttonsToskip1 {
  background-color: white;
  border: 0.2vw solid black;
  padding: 0.5vw 1vw;
  /* color: #000; */
}

.buttonsToskip2 {
  background-color: black;
  padding: 0.5vw 1vw;
  color: white;
  /* color: #000; */
}

.questionOfQma {
  position: relative;
}

.slecttheOption {
  list-style-type: none;
  /* Removes the bullets */
  padding: 0;
  /* Removes default padding */
  margin: 0;
  /* Removes default margin */
  position: absolute;
  font-size: 1vw;
  height: 20vh;
  overflow-y: scroll;

}

.slecttheOption::-webkit-scrollbar {
  display: none;
  /* Hides the scrollbar in Webkit browsers (e.g., Chrome, Safari) */
}

.slecttheOption li {
  cursor: pointer;
  background-color: white;
}

.optionsToTickQMA {
  display: flex;
  flex-wrap: wrap;
  width: 40vw;
  gap: 2vw;
  margin-top: 3vw;
}

.optionToTickQMA {
  width: 15vw;
  border: 0.1vw solid #0E202A3D;
  padding: 0.5vw 1vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tickInQMA {
  /* position: absolute; */
  right: 0.5vw;
  border: 0.1vw solid #0E202A3D;
  font-size: 1;
  padding: 0.1vw 0.5vw;
  top: 0.3vw;
  height: 2vw;
  width: 2vw;
}

.tickInQMAA {
  position: absolute;
  right: 1.5vw;
  font-size: 1;
  top: 0.6vw;
}

.gridderQMA {
  /* display: grid;
  grid-template-columns: repeat(4, max-content); */
  /* justify-content: center; */
  /* gap : 0.1vw; */
  display: flex;
  flex-wrap: wrap;
  gap: 0.6vw;
  width: 35vw;
  margin-top: 1vw;
}

.gridderSelecetQMA {
  display: flex;
  flex-wrap: wrap;
  /* width: max-content; */
  font-size: 1.2vw;
  border: 0.1vw solid #0E202A3D;
  padding: 0.6vw 1.2vw;
  text-align: center;
}

.mkls {
  margin-right: 1.3vw;
}

.inputForQNAH {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 20vw;
  width: 40vw;
  height: max-content;
  background-color: white;
  right: 5vw;
  padding: 1vw 2vw;
  align-items: center;
  justify-content: center;
  border: 0.1vw solid #0E202A3D;
}

.inputForQNAH input {
  width: 30vw;
  border: 0.1vw solid #0E202A3D;
  padding: 0.5vw 1vw;
  outline: none;
  font-size: 1.3vw;
}

.inputForQNAH .gsb {
  font-size: 0.8vw;
  margin-top: 1vw;
}

.inputForQNAH button {
  background-color: white;
  border: 0.2vw solid black;
  padding: 0.3vw 0.6vw;
  outline: none;
  font-size: 0.8vw;
  margin-top: 0.5vw;
}

.PBannerP {
  margin-top: 10vw;
  padding: 1vw 2vw;
}

.profileBanner {
  background-color: #FFCB00;
  padding: 1.5vw 2vw;
  display: flex;
  align-items: center;
}

.profileBannerText {
  display: flex;
  flex-direction: column;
}

.PBTHead {
  font-size: 2vw;
}

.PBTDescription {
  font-size: 1vw;
  width: 45%;
  margin-top: 1vw;
}

.profileBannerButton {
  width: max-content;
  background-color: #000;
  color: white;
  outline: none;
  border: none;
  padding: 0.8vw 1.5vw;
}

.PV2D1 {
  display: flex;
  flex-direction: column;
}

.ProfileV2 {
  padding: 1vw 8vw;
  display: flex;
  justify-content: space-between;
  margin-top: 16vh;
}

.inputRow {
  display: flex;
  width: 75%;
  justify-content: space-between;
}

.V2D1IMG img {
  width: 20vw;
  object-fit: cover;
  height: 25vw;
  margin-top: 2vw;
}

.V2D1Name {
  font-size: 1.8vw;
  text-align: center;
  width: 20vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.V2D1Share {
  background-color: #000;
  color: white;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  width: 20vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  justify-content: center;
  outline: none;
  border: none;
  margin-bottom: 3vw;
}

.iconV2D1 {
  margin-right: 1vw;
}

.V2D1ActionItems {
  display: flex;
  flex-direction: column;
}

.V2D1ActionItems span {
  cursor: pointer;
  font-size: 1.5vw;
  margin-bottom: 1.5vw;
}

.iconV2D12 {
  height: 1.5vw;
  margin-left: 2vw;
}


.languageSelection {
  display: block;
  font-size: 1.5vw;
  margin-bottom: 1vw;
  color: #0000008F;
}

.formForSlecetion {
  width: max-content !important;
  font-size: 1vw !important;
}

.spanInOption {
  width: fit-content !important;
  font-size: 1vw !important;
  color: #0000008F !important;
}

.CheckboxinFormSelection {
  width: max-content;
}

.optionGrid {
  width: 30% !important;
  color: #0000008F !important;
}

.PV2D2 {
  margin-left: 10vw;
}

.PV2D2Form {
  display: flex;
  flex-direction: column;
  gap: 3vw;
  margin-top: 5vw;
}

.datePickerInForm {
  width: 18.5vw;
}

.otherLanguagesBox {
  margin-top: 2vw !important;
  font-size: 1.5vw !important;
  width: 75%;
}

.dottedLineInForm {
  border-top: 0.2vw dotted #0000003D;
  width: 75%;
  height: 1% !important;
}

.aboutYourselfForm {
  width: 75%;
}

.aboutYourselfForm input {
  height: 100% !important;
}

.aboutDivYour {
  height: 11.5vw;
}

.educationQualfForm {
  width: 75%;
}

.headingInFormSpan {
  font-size: 2vw;
  margin-bottom: 1.5vw;
  display: block;
}

.tagsInFormDiv {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
}

.tagsInFormDiv span {
  color: #0000007A;
  border: 0.1vw solid #0000007A;
  padding: 0.4vw 0.8vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
}

.buttonInFormSpan {
  margin-left: 60%;
  font-size: 1.4vw;
}

.addLinks {
  display: flex;
  flex-direction: column;

}

.LinkSvg {
  width: 4vw;
}

.addLinks input {
  outline: none;
  border: none;

}

.addLinks span {
  border: 0.1vw solid #0000007A;
  margin-bottom: 1vw;
  width: 75%;
  padding: 0.3vw 0.6vw;
}

.classTabsForReg div {
  display: flex;
  gap: 3vw !important;
  justify-content: center;
}

.tacHead {
  text-align: center;
  font-size: 3vw;
  margin-top: 7vw;
}

.headingForClassInfo {
  font-size: 2vw !important;
  letter-spacing: -2px;
}

.ClassInfoKnowledge {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  border-bottom: 0.2vw dotted #0000001F;
}

.classInfoDateStruct {
  display: flex;
  gap: 1vw;
  margin-bottom: 1vw;
}

.showingDateAndTime {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.nooooooooooooo {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  top: 0;
  left: 0;
  z-index: 234563;
}

.whiteboxInn {
  background-color: white;
  width: 30%;
  height: 50%;
  margin-top: 10vw;
  margin-left: 35vw;
  padding: 1vw 2vw;
  font-size: 1.2vw;
  z-index: 3456787654;
  position: relative;
}

.dateandtime {
  overflow-y: scroll;
  display: flex;
  gap: 1vw;
  flex-direction: column;
  margin-top: 1.5vw;
  height: 80%;
}

.dateandtime .data {
  display: flex;
}

.dateandtime .data .dataCl {
  color: #000000B8;
  width: 4.8vw;
}

.dateandtime .data .dataDa {
  padding-left: 0.5vw;
  border-right: 2px solid #0000003D;
  width: 10vw;
}

.dateandtime .data .dataTi {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}


.whiteboxInn .head {
  font-size: 1.2vw;
  color: #000000B8;
}

.dateandtime::-webkit-scrollbar {
  display: none;
}

.classInfoDateStruct .dark {
  font-size: 1.2vw;
}

.classInfoDateStruct .faded {
  font-size: 1.2vw;
  color: #0000008F;
}

.ClassInfoFormD {
  display: flex;
  flex-direction: column;
  margin-bottom: 3vw;
}

.CIFDTitle {
  font-size: 1.2vw;
  margin-bottom: 1vw;
}

.CIFDInput input {
  padding: 0.5vw 1vw;
  outline: none;
  border: none;
  border: 0.1vw solid #0000001F;
  width: 100%;
  font-size: 1vw;
}

.CIFDInput textarea {
  padding: 0.5vw 1vw;
  outline: none;
  border: none;
  border: 0.1vw solid #0000001F;
  width: 100%;
  font-size: 1vw;
}

.CIFDInput #message {
  outline: none;
  border: none;
  border: 0.1vw solid #0000001F;
  width: 100%;
  font-size: 1vw;
  margin-bottom: 1vw;
}

#cars {
  padding: 0.5vw 1vw;
  outline: none;
  border: none;
  border: 0.1vw solid #0000001F;
  width: 100%;
  font-size: 1vw;
  background-color: white;
}

#cars option {
  font-size: 1vw;
  background-color: white;
  outline: none;
  border: none;
  border: 0.1vw solid #0000001F;
}

.info3CIFDHost {
  color: #00000085;
  font-size: 1vw;
  width: 90%;
  display: block;
}

.checkbox-container {
  position: relative;
  width: 100%;
  height: 2vw;
  /* Adjust height as needed */
  margin-bottom: 2vw;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container label {
  position: absolute;
  left: 0.8vw;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5vw;
  /* Adjust size as needed */
  height: 1.5vw;
  /* Adjust size as needed */
  border: 2px solid #000;
  /* Change border color as needed */
  background-color: transparent;
  cursor: pointer;
}

.checkbox-container .tick {
  display: none;
}

.checkbox-container .tick.checked {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Change tick color as needed */
  font-size: 1.2vw;
  /* Adjust font size as needed */
}

.checkbox-container input[type="checkbox"]:checked+label .tick {
  display: block;
}

.checkbox-container input[type="checkbox"]:checked+label {
  background-color: #000;
  /* Change background color when checked */
}

.CIKPricing1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vw;
}

.dino {
  gap: 4vw;
  margin-bottom: 2vw;
}

.spanForFreeClass {
  margin-left: 3.5vw;

}

.priceHead {
  display: block;
}

.priceInputsBank {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  margin-bottom: 2vw;
}

.priceInputsBank1,
.priceInputsBank2 {
  width: 48% !important;
}

.priceInputsBank3,
.priceInputsBank4,
.priceInputsBank5 {
  width: 30% !important;
}

.orInInputBank {
  margin-bottom: 2vw !important;
  display: block;
}

.Claser {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.VFDTitle {
  font-size: 1.2vw;
  width: 80%;
  margin-bottom: 3vw;
  margin-top: 1vw;
  letter-spacing: 0px;
  line-height: 1.4vw;
}

.VFDTitle ul {
  margin-top: 1vw;
  margin-left: 0;
  padding-left: 1.5vw;
}

.VFDTitle ul li {
  margin-bottom: 0.5vw;
}

.imageBox {
  width: 20% !important;
  height: 24vh !important;
  background-color: #0000000A;
  border: 0.1vw dotted #6866668f;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  object-fit: cover;
  flex-shrink: 0;
    flex-grow: 0;
}



.f2 {
  font-size: 5vw !important;
  color: #0000007A;
  font-weight: 200;
}


.f22 {
  font-size: 1vw;
  color: #0000007A;
}

.file-info {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
}

.sizeOfFile {
  font-size: 1vw;
}

.uploaded-file-box {
  margin-top: 2vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sixeFileDel {
  background-color: white !important;
  outline: none !important;
  border: none !important;

}

.jakeAndBean {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
}

.jakeAndBean button {
  background-color: black;
  text-transform: capitalize !important;
}

.jakeAndBean2 {
  display: flex;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  justify-content: space-between;
  margin-top: 2vw;
}

.jakeAndBean2 .butto1 {
  background-color: white;
  border: 0.1vw solid black;
  color: #000;
  text-transform: capitalize !important;
}

.jakeAndBean2 .butto2 {
  background-color: black;
  text-transform: capitalize !important;
}

.jakeAndBean2 .butto1:hover {
  background-color: white;
  border: 0.1vw solid black;
  color: #000;
}

.jakeAndBean2 .butto2:hover {
  background-color: black;
}

.CPr1 {
  color: #0000008F;
  font-size: 1.3vw;
  text-align: center;
  margin-bottom: 3vw;
}

.CPr2Banner {
  background-color: #F8F3E3;
  display: flex;
  width: 105%;
  margin-left: -2%;
  padding: 2vw 8vw;
  justify-content: space-between;
}

.CPR2BInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.CPR2Tag {
  padding: 0.5vw 1vw;
  background-color: #0000000A;
  max-width: fit-content;
  font-size: 0.7vw;
  text-transform: uppercase;
}

.CPR2Head {
  font-size: 3.7vw;
  line-height: 3vw;
  margin-top: 2vw;
  margin-bottom: 1vw;
  letter-spacing: -2px;
  text-transform: capitalize;
}

.CPR2Stars span {
  font-size: 1vw;
}

.CPR2Stars {
  display: flex;
}

.CPR2Starss {
  margin-right: 0.5vw;
  display: flex;
  gap: 0.25vw;
}

.CPR2Auth {
  display: flex;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.geryCirc {
  background-color: #D9D9D9;
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  margin-right: 0.4vw;
}

.CPR2Auth span {
  font-size: 1.3vw;
  color: #0000008F;
  letter-spacing: -1px;
}

.CPR2AuthName {
  font-size: 1.5vw;
  color: #000 !important;
  margin-left: 0.4vw;
}

.CPR2AuthDesc {
  font-size: 1.4vw;
  line-height: 1.8vw;
  color: #000;
  margin-bottom: 1vw;
  letter-spacing: -1px;

}

.CPR2Timing {
  background-color: #0000000A;
  padding: 0.5vw 0.5vw;
  font-size: 1.2vw;
  width: fit-content;
  display: flex;
}

.CPR2TimingD {
  border-right: 0.1vw solid #0000003D;
  /* display: flex; */
}

.ic img {
  margin-bottom: 0.2vw;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
}

.CPR2TimingD .a {
  color: #000000B8;
  font-size: 1vw;
  margin-right: 1vw;
  cursor: pointer;
}

.d {
  margin-right: 0.5vw;
}

.CPR2Studs {
  background-color: #000000B8;
  color: #FFCB00;
  font-size: 1.2vw;
  width: max-content;
  padding: 0.5vw 1vw;
  margin-top: 2vw;
  letter-spacing: -1px;
}

.CPR2TimingT {
  border-right: 0.1vw solid #0000003D;
}

.imgInCPR2B img {
  width: 100% !important;
  height: 55vh !important;
  /* object-fit: cover;
  object-position: top; */
  object-fit: contain;
  object-position: center;
}

.CPR2BImage {
  width: 46%;
}

.imgInformation {
  display: flex;
  justify-content: space-between;
  padding: 1vw 0.1vw;
  align-items: center;
}

.informationInfo {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.priceyyy {
  font-size: 2.2vw;
  letter-spacing: -2px;
}

.slotsssss {
  color: #0000007A;
  font-size: 1.1vw
}

.buyNowInTabs {
  background-color: #FFCC00;
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5vw 1vw;
  font-size: 1.3vw;
  margin-bottom: 1vw;
  letter-spacing: -1px;
}

.addCartInTabs button {
  border: 0.1vw solid black;
  background-color: transparent;
  outline: none;
  width: 49%;
  padding: 0.5vw 1vw;
  cursor: pointer;
  letter-spacing: -1px;
}

.addCartInTabs {
  display: flex;
  gap: 1vw;
}

.jjkolmn {
  width: 1.6vw;
  height: 1.6vw;
  margin-right: 1vw;
  margin-bottom: 0.5vw;
}

.ljdojasy {
  margin-left: 2vw !important;
  display: block;
}

.introInPr {
  display: flex;
  flex-direction: column;
  margin-top: 2vw;
}

.imgInPr55 {
  width: 10vw;
  height: 12vw;
  object-fit: cover;
  margin-top: 1vw;
  margin-right: 2vw;
}

.prName {
  font-size: 1.5vw;
  text-decoration: underline;
}

.popupInCS {
  position: fixed;
  top: 25%;
  width: 100%;
  height: 100vh;
  left: 25%;
}

.popupInCSW {
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 2vw 4vw;
  /* border: 0.1vw solid black; */
  box-shadow: 0.1vw 0.1vw 8px rgba(0, 0, 0, 0.5);

}

.gyyyta {
  text-align: center;
  margin-top: 1vw;
}

.jolojoloj {
  font-size: 1vw;
}

.jakallakak button {
  width: 35%;
}

.jakallakak {
  align-items: center;
  justify-content: center;

}

.jakallakak .f {
  background-color: white;
  color: #000;
  font-size: 1.3vw;
  border: 0.1vw solid black;
  margin-top: 2vw;
}

.jakallakak .t {
  background-color: black;
  color: white;
  font-size: 1.3vw;
  border: none;
  margin-top: 2vw;
}

.jooooi {
  margin-top: 8vw;
  margin-left: 25%;
}

.jooooi .headingForClassInfo {
  font-size: 2.5vw !important;
  line-height: 2.5vw;
}

.jokkkkkkk {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
}

.TAQB {
  margin-bottom: 10vw;
}

.TAQB .f {
  background-color: white;
  color: #000;
  font-size: 1.3vw;
  border: 0.1vw solid black;
  margin-top: 2vw;
  width: 45%;
  margin-right: 5%;
  padding: 0.5vw 1vw;

}

.TAQB .t {
  background-color: black;
  color: white;
  font-size: 1.3vw;
  border: none;
  margin-top: 2vw;
  width: 45%;
  padding: 0.5vw 1vw;
}


.Confirmations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.Confirmation1 {
  font-size: 4vw;
  letter-spacing: -1.08px;
  line-height: 4vw;
}

.Confirmation2 {
  font-size: 1.5vw;
  width: 70%;
  margin-bottom: 1.5vw;
  line-height: 1.7vw;
  letter-spacing: -0.4px;
}

.Confirmation3 button {
  background-color: transparent;
  color: #000;
  font-size: 1.5vw;
  border: 0.1vw solid black;
  width: 110%;
  margin-right: 5%;
  padding: 0.5vw 1vw;
  line-height: 1.7vw;
  letter-spacing: -0.4px;
}


.Confirmation4 button {
  background-color: black;
  color: white;
  font-size: 1.3vw;
  border: 0.1vw solid black;
  margin-top: 2vw;
  width: 110%;
  margin-right: 5%;
  padding: 0.5vw 1vw;
}

.Confirmation5 {
  font-size: 1.5vw;
  width: 70%;
  margin-top: 1vw;
  line-height: 1.7vw;
  letter-spacing: -0.4px;
}

.ClassV2VJ {
  margin-top: 6vw;
}


.blackBannerInClassE {
  background-color: #FFFBEB;
  box-shadow: 0.01vw 0.01vw 8px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: -10vw;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  padding: 1.5vw 3vw;
  align-items: center;
}

.topperBlackBannerInClassE {
  position: fixed;
  top: 4.5vw;
  z-index: 1;
  width: 100%;
}


.blackBannerInClassE.enter {
  transition: top 0.5s ease-in;
  /* Smooth entry */
}

.blackBannerInClassE.exit {
  transition: top 0s ease-out;
  /* Quick exit */
}

.blackBannerInClassEHeading {
  font-size: 1.8vw;
  letter-spacing: -2px;
  line-height: 1.9vw;
}

.blackBannerInClassEButtonSection {
  display: flex;
  align-items: center;
}

.blackBannerInClassErupee {
  font-size: 2.3vw;
  margin-right: 1.5vw;
  line-height: 2.4vw;
  letter-spacing: -2px;
}

.blackBannerInClassEnroll button {
  color: white;
  background-color: #000;
  padding: 0.5vw 1vw;
  letter-spacing: -1px;
  border: none;
  outline: none;
  margin-right: 0.5vw;
  font-size: 1.35vw;
}

.blackBannerInAddToCart button {
  color: black;
  background-color: transparent;
  padding: 0.4vw 1vw;
  border: 0.1vw solid black;
  outline: none;
  margin-right: 0.5vw;
  font-size: 1.35vw
}

.blackBannerInShareIcon {
  position: relative;
}

.share-options23 {
  background-color: white;
  position: absolute;
  top: 200%;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  /* align-items: center; */
  padding: 1vw;
  left: 0vw;
  font-size: 1.2vw;
  color: black !important;
}

.share-options23 a {
  color: black;
}

.blackBannerInShareIcon img {
  width: 1.3vw;
  margin-right: 2vw;
  margin-left: 2vw;
}

.blackBannerInGiftIcon img {
  width: 1.5vw;
  margin-right: 0.5vw;
}

.blackBannerInGiftIcon {
  display: flex;
  align-items: center;
}

.blackBannerGTAF {
  font-size: 1.3vw;
}

.classFinReview {
  background-color: #F7E49C52;
  padding: 2vw 4vw;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.CPRFDiv {
  display: flex;
  flex-direction: column;
  width: 100vw !important;
}

.CPRFDiv .CFRInfo {
  margin-top: 2vw;
  display: flex;
  gap: 2vw;
}

.CFRInfo .students {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 0.5vw;
}

.students .head {
  font-size: 2vw;
  display: flex;
  align-items: center;
}

.students .head span {
  margin-left: 0.5vw;
  font-size: 1.5vw;
}

.students .bottom {
  font-size: 1.3vw;
  text-transform: uppercase;
}

.CPRTDiv {
  display: flex;
  width: fit-content;
  margin-top: 4vw;
}

.CPRTDiv .greyBox {
  width: 7vw;
  height: 7vw;
  background-color: #D9D9D9;
  margin-right: 1.5vw;
}

.CPRTDiv .nextToGreyBox {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.CPRTDiv .nextToGreyBox .infoooo {
  font-size: 1.2vw;
  margin-bottom: 0.5vw;
}

.CPRTDiv .nextToGreyBox .infooooName {
  color: #1100007A;
  font-size: 1vw;
}

.popupForAddedToCart {
  background-color: white;
  width: 40%;
  position: fixed;
  top: 25%;
  display: flex;
  flex-direction: column;
  padding: 1.5vw 3vw;
  left: 30%;
}

.HeadInPFATC {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeadInPFATC .one {
  font-size: 1.8vw;
}

.HeadInPFATC .two {
  font-size: 1vw;
}

.InfoInPFATC {
  display: flex;
  margin-top: 0.8vw;
}

.PFATCIM img {
  width: 10vw;
  height: 7vw;
  object-fit: cover;
}

.PFATCIT {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
}

.PFATCIT .o {
  font-size: 1vw;
}

.PFATCIT .t {
  font-size: 0.8vw;
  margin-top: 0;
  line-height: 1vw;
  color: #000000A3;
}

.PFATCITT {
  margin-top: 1vw;
  font-size: 0.8vw;
  color: #000000A3;

}

.PFATCITT .oo {
  border-right: 0.1vw solid #000000A3;
  padding-right: 1vw;
  margin-right: 1vw;
}

.tttt {
  margin-right: 0.5vw;
}

.PFATCIT .price {
  font-size: 1vw;
  margin-top: 1vw;
}

.GTCInJCI {
  margin-top: 1vw;
  outline: none;
  background-color: black;
  border: none;
  color: white;
  font-size: 1vw;
  padding: 0.5vw;
}

.drrrrrrr {
  text-align: center;
  width: 100%;
}

.sgggggggggg {
  width: 40%;
  margin-left: 30%;
}

.jaojdoaajs {
  top: 10%;
  z-index: 23940;
  box-shadow: 0.1vw 0.1vw 8px rgba(0, 0, 0, 0.5);
}

.iGTC {
  font-size: 1vw;
  border: 0.1vw solid #0E202A1F;
  padding: 0.4vw;
  outline: none;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.taGTC {
  font-size: 1vw;
  border: 0.1vw solid #0E202A1F;
  padding: 0.4vw;
  outline: none;
  /* margin-top: 1vw; */
  margin-bottom: 1vw;
  resize: none;
}

.cartMain {
  margin-top: 6vw;
  padding: 1vw 3vw;
}

.cartMain .head {
  display: flex;
  flex-direction: column;
}

.cartMain .head .main {
  font-size: 3vw;
  line-height: 3.4vw;
}

.cartMain .head .desc {
  font-size: 1.2vw !important;
}

.cart-item {
  display: flex;
  flex-direction: column;
  min-width: 30vw;
}

.bgGreyLine {
  border-bottom: 0.1vw solid #0000003D;
  height: 1vw;
  margin-top: 2.2vw;
}

.cart-item .item {
  padding-bottom: 2vw;
  border-bottom: 0.1vw solid #0000003D;
  display: flex;
  margin-top: 4vw;
}

.cart-item .item .item-1-1 {
  width: 20vw;
  height: 13vw;
}

.cart-item .item .item-1-1 img {
  width: 20vw;
  height: 13vw;
  object-fit: cover;
}

.item .desc {
  width: 35vw;
  margin-left: 2vw;
}

.item .desc .name {
  font-size: 2vw;
  width: 28vw;
  margin-bottom: 1vw;
  line-height: 2.2vw;
}

.item .desc .auth {
  font-size: 1.5vw;
  color: #0000008F;
  margin-bottom: 1vw;
}

.item .desc .dttt {
  color: #0000008F;
  font-size: 1.2vw;
  /* width: 10vw;
  display: flex; */
}

.item .desc .dttt .timem {
  padding-right: 1vw;
  border-right: 0.1vw solid #0000003D;
}

.item .desc .dttt .rating {
  width: fit-content !important;
  margin-left: 1vw;
}

.item .desc .dttt .rating span {
  margin-right: 0.5vw !important;
}

.priceInCart {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  align-items: self-end;
}

.priceInCart .priceforItemInCart {
  font-size: 2.5vw;
}

.priceInCart .removeforItemInCart,
.priceInCart .whishlistforItemInCart {
  color: #000000B8;
  font-size: 1.2vw;
  cursor: pointer;
}

.cart-view {
  display: flex;
}

.cart-bill {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-left: 8vw;
  margin-top: 3.2vw;
}

.cart-bill .totoal {
  font-size: 2vw;
  line-height: 2.1vw;
}

.cart-bill .finalPrice {
  font-size: 3.5vw;
}

.cart-bill .checkOutButt {
  font-size: 2vw;
  background-color: #000;
  color: white;
  padding: 0.5vw 4vw;
  margin-top: 1vw;
}

.cart-bill .promotionTitl {
  font-size: 2vw;
  margin-top: 3vw;
}

.cart-bill .promotionInput {
  width: 17vw;
  height: 4vw;
  background-color: #D9D9D9;
  margin-top: 1vw;
}

.verifyBannerInClass {
  margin-top: 10vw;
  background-color: #ffcc00;
  padding: 1vw 4vw;
  display: flex;
  justify-content: space-between;
  margin-bottom: -5vw;
}

.verifyBannerInClass button {
  background-color: #000;
  color: #FFCC00;
  padding: 1vw 1.5vw;
  font-size: 1.5vw;
  outline: none;
  border: none;
  text-align: center;
  width: 20%;
}

.mmmmmmm {
  margin-top: -1vw;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  cursor: default !important;
}

.deleteButtonOfC2 {
  background-color: white !important;
  border: 0.1vw solid#000 !important;
  color: #000 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.AddButtonOfC2 {
  background-color: black !important;
  border: 0.1vw solid#000 !important;
  color: white !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.fscc {
  font-size: 1.5vw !important;
}

.fscc2 {
  font-size: 1vw;
}

.jjjssskkkkssskkk {
  display: flex;
  gap: 1vw;
  position: relative;
}

.jjjssskkkkssskkk img {
  cursor: pointer;
}

.wishIconHahahaha:hover+.wishIconHahahaha path {
  fill: #FFCB00;
}

















/* .circle-text-container {
  display: flex;
  align-items: center;
  padding: 0.5vw;
  border-radius: 2px;
  padding-left: 1vw;
  margin-right: 2vw;
  padding-top: 0.25vw;
  padding-bottom: 0.25vw;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  margin-right: 8px;
  animation: grow-shrink 1s infinite;
} */

.circle {
  display: none;
}

.circle-text-container {
  margin-right: 2.5vw;
}

@keyframes grow-shrink {

  0%,
  100% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(0.9);
  }
}

.cart-icon-head {
  margin-left: 2vw;
}

.bell-icon-head {
  margin-left: 2vw;
}

.dataStruct {
  background-color: #EAEAEA52;
  padding: 1.5vw;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.6vw;
  font-size: 1.3vw;
}

.dataStruct1 {
  display: flex;
  gap: 3vw;
}

.dsctructDile {
  color: #000000B8;
}

.dataStructDiv {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-top: 1vw;
  margin-bottom: 3vw;
}

.container33 {
  position: relative;
  display: flex;
  align-items: center;
}

.dot33 {
  position: absolute;
  right: -1.5vw;
  /* Adjust to position the dot correctly */
  font-weight: bold;
  top: -1.2vw;
  color: black;
  background-color: black;
  width: 0.30vw !important;
  height: 0.30vw !important;
  border-radius: 50%;
  z-index: 0;
}

.dataCdates {
  display: flex;
  font-size: 1.3vw;
  gap: 2vw;
}

.dataCldates {
  color: #000000B8;
}

.jadyyRann {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.selcterForCurrency {
  width: 100% !important;
}

.css-t3ipsp-control:hover {
  border-color: #000 !important;
  border: 0.1vw solid #0000001F !important;

}

.css-t3ipsp-control {
  box-shadow: none !important;
  border: 0.1vw solid #0000001F !important;
}

.linkOfCart {
  position: relative;
}

.linkOfCartLength {
  color: white;
  position: absolute;
  top: -14px;
  background-color: #000;
  display: block;
  width: 1.7vw;
  height: 1.7vw;
  font-size: 1vw;
  text-align: center;
  border-radius: 50%;
  right: -10px;
  border: 0.2vw solid white;
}


.placeholder-container {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  left: 5.5vw;
  top: 24%;
  z-index: 22 !important;
}

input {
  padding-right: 100px;
  /* Adjust for the word length */
}

@keyframes slideUpAndDown {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  10% {
    transform: translateY(-100%);
    opacity: 0;
  }

  20% {
    transform: translateY(100%);
    opacity: 0;
  }

  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.animationHappensHere {
  animation: slideUpAndDown 4s ease-in-out infinite;
  transition: transform 4s ease-in-out !important;
  animation-delay: 3s;
  color: rgb(112, 111, 111);
  font-size: 1.3vw;
  /* animation-delay: 0.5s; */
}

.pprreell {
  position: relative;
}

.firstLearnText {
  font-size: 2vw;
}

.pprreell input {
  border: 1px solid #0000001F !important;
  border-radius: 0.2vw;
  padding: 0.8vw 1vw;
  width: 45vw;
  font-size: 1.3vw;
  outline: none;
}


.latesCOurseL {
  width: 20vw !important;
  height: 45vh !important;
  flex: 0 0 25vw;
  cursor: pointer;
}

.wishIconLCL {
  position: absolute;
  width: 2vw !important;
  height: 2vw !important;
  bottom: 1vw;
  right: 1vw;
  cursor: pointer;
}

.oldIconsmall {
  width: 1.6vw !important;
  height: 1.6vw !important;
  bottom: 1.2vw;
  right: 1.2vw;
}

.latesCOurseL .img {
  width: 100%;
  height: 70%;
  position: relative;
}

.latesCOurseL .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.jsodjosjdo {
  gap: 2vw;
  scrollbar-width: none;
  overflow: hidden;
  flex-basis: 20vw;
  width: 100%;
  flex-wrap: nowrap;
  /* Change to wrap if you want items to wrap */
  overflow-x: auto;
}

.LCLDecr {
  display: flex;
  flex-direction: column;
  padding: 1vw 1vw;
}

.TitlePri {
  display: flex;
  justify-content: space-between;
  font-size: 1.2vw;
}

.LCLTitle {
  line-height: 1.5vw;
  text-transform: capitalize;
}

.LCLNameRate {
  display: flex;
  justify-content: space-between;
  color: #0000008F;
  font-size: 1vw;
  align-items: center;
}

.LCLName {
  display: flex;
  gap: 0.5vw;
}

.LCLRateTime {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.LCLRate {
  border-right: 1px solid #0000003D;
  padding-right: 0.3vw;
  height: 1.2vw;
  display: flex;
  align-items: center;
}

.LCLRate img {
  width: 1vw;
  height: 1vw;
  margin-right: 0.2vw;
}

.LCLRate2 img {
  margin-bottom: 0.2vw;
}

.filters {
  width: 18%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  margin-top: 4vw;
  position: relative;
}

.gridderForCourse {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}


.gridderForCourse {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  margin-left: 2vw;
}

.imgClassGrid {
  width: 100%;
  height: 12.974vw;
  object-fit: cover;
}

.ClassGridBox {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
  position: relative;
}

.awesomePopup {
  position: absolute;
  z-index: 3;
  top: 1vw;
  left: 103%;
  background-color: #ffffff;
  width: 20vw;
  height: max-content;
  padding: 1vw;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
}

.jodjddojdodjdojdojdojdodj {
  right: 203% !important;
  left: -90% !important;
}


.triangle {
  position: absolute;
  width: 0;
  height: 0;
  /* border-left: 10px solid transparent; */
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  border-top: 10px solid transparent;
  left: -10px;
  top: 30%;
}

.righhhhh {
  position: absolute;
  width: 0;
  height: 0;
  /* border-left: 10px solid transparent; */
  border-bottom: 10px solid transparent;
  border-left: 10px solid white;
  border-top: 10px solid transparent;
  border-right: none;
  left: 100%;
  top: 30%;
}

.apTitle {
  font-size: 2vw;
  line-height: 2vw;
}

.aptimeLan {
  font-size: 1vw;
  display: flex;
  margin-top: 1.5vw;
  margin-left: 0.2vw;
  color: #0000008F;
  gap: 0.5vw;
}

.aptimeLan .mkmk {
  border-right: 1px solid #6e6e6e60;
  padding-right: 0.5vw;
}

.apDescre,
.apDescre p,
.apDescre ul li {
  font-size: 1.2vw;
  text-wrap: wrap;
  text-overflow: clip;
  overflow: hidden;
  max-height: 28vh;
  margin-bottom: 1vw;
  text-overflow: ellipsis;
}

.apDescre {
  margin-top: 1.5vw;
}

.homepageLoggedin {
  position: relative;
}

.sideStrollerHLIP {
  position: fixed;
  background-color: #f7e39c6e;
  /* width: 5vw; */
  height: 86vh;
  top: 14vh;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding-top: 2vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.sideStrollerHLIP {
  position: fixed;
  background-color: #f7e39c6e;
  /* width: 5vw; */
  height: 89vh;
  top: 11vh;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding-top: 4vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.sideStrollerHLIP .chatIcon img {
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
}

.sideStrollerHLIP .friendIcon img {
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
}

.contentHLIP {
  margin-top: 14vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FBF9F1
}

.f1HLIP {
  display: flex;
  width: 50vw;
  margin-top: 3vw;
  gap: 1vw;
  align-items: center;
  justify-content: flex-start;
}

.imgForHostF1HLIP img {
  object-fit: cover;
  width: 5vw;
  height: 5vw;
  border-radius: 4%;
}


.f12HLIP {
  color: rgba(23, 26, 31, 0.56);
  font-size: 1vw;
}

.f11HLIP {
  font-size: 2vw;
  line-height: 2.5vw;
}

.f2HLIP {
  display: flex;
  gap: 1vw;
  margin-top: 1.5vw;
}

.f21HLIP {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.f21HLIP img {
  width: 1.5vw;
  height: 1.5vw;
}

.dynamicInputBoxHLIP {
  width: 40vw;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.72);
  height: 8vh;
  font-size: 1.2vw;
  transition: height 0.3s ease-in-out;
}

.jtreeKKK34 {
  width: 100%;
  height: 100%;
  padding: 0.8vw 1vw;
}

.dynamicInputBoxHLIP.expanded {
  height: 20vh;
  /* Adjust the height as needed */
  transition: height 0.3s ease-in-out;
  padding: 0;
  margin-bottom: 4vw !important;
}

.custom-quill {
  height: 100%;
}

.custom-quill .ql-container {
  border: none;
  overflow-y: auto;
}

.custom-quill .ql-editor {
  max-height: 13vh;
  /* Adjust the max height as needed */
  height: 13vh !important;
  outline: none;
}

.f1jjkLq {
  height: 20vh !important;
}

.custom-quill .ql-editor::-webkit-scrollbar {
  display: hidden;
}

.custom-quill .ql-toolbar {
  border: none;
}

#mmmm .ql-container {
  border: none;
  /* Remove border */
  width: 100%;
}

#mmmm .ql-editor {
  outline: none;
  /* Remove outline */
  height: 100% !important;
  scrollbar-width: 0px !important;
}

#mmmm .ql-toolbar {
  border: none;
  /* Remove toolbar border if needed */
}

#mmmm {
  height: 100% !important;
}

.button2ForHLIP {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #000 !important;
  border: 2px solid black !important;
  border-radius: 0% !important;
  width: 9vw;
  font-size: 1.3vw !important;
  text-transform: capitalize !important;
}

.button1ForHLIP {
  background-color: rgb(0, 0, 0) !important;
  color: #ffffff !important;
  border: 2px solid rgb(0, 0, 0) !important;
  border-radius: 0% !important;
  width: 9vw;
  font-size: 1.3vw !important;
  text-transform: capitalize !important;
}

.buttonGroupHLIP {
  margin-top: 1vw;
  display: flex;
  gap: 1vw;
  justify-content: end;
  margin-bottom: 4vw;
}

.S1HLIP {
  margin-top: 4vw;
}

.titleOfHLIPeverySection {
  position: relative;
  width: max-content;
}



.box1 {
  background-color: #000;
  border: 2px solid black;
  width: 0.4vw;
  height: 0.4vw;
  position: absolute;
  top: 35%;
  left: 0%;
}

.box2 {
  background-color: #000;
  border: 2px solid black;
  width: 0.4vw;
  height: 0.4vw;
  position: absolute;
  top: 35%;
  right: -20%;
}

.box21 {
  left: 45% !important;
  top: 25% !important;
}

.box22 {
  right: 41% !important;
  top: 25% !important;
}


.titleOfHLIPeverySection .title {
  margin-right: 0;
  font-size: 1.2vw !important;
  margin-bottom: 1.5vw;
  margin-left: 2vw;
  width: fit-content;
}

.secondHLIP {
  width: 85%;

}

.S2HLIP {
  display: flex;
  gap: 2vw;
  overflow: hidden;
  /* box-shadow:
    10px 0 20px rgba(255, 255, 255, 0.6),
    15px 0 25px rgba(255, 255, 255, 0.4),
    20px 0 30px rgba(255, 255, 255, 0.3),
    25px 0 35px rgba(255, 255, 255, 0.2),
    30px 0 40px rgba(255, 255, 255, 0.1); */
}

.S2HLIP::before {
  content: '';
  position: absolute;
  right: -15px;
  top: 40px;
  width: 10vw;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.788); */
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0.466) 0%, rgba(255, 255, 255, 0.87) 100%); */
  background: linear-gradient(93deg, rgba(255, 255, 255, 0.00) 2.45%, #FFF 97.14%);
  filter: blur(20px);
  display: block;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: inherit;
  pointer-events: none;
  z-index: 12;
}

.secondHLIP {
  position: relative;
  margin-bottom: 6vw;
}

.arrowleftbl {
  position: absolute;
  left: -1vw;
  z-index: 21;
  top: 45%;
  background: #F7E49C;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowrightbl {
  position: absolute;
  right: -1vw;
  z-index: 21;
  top: 45%;
  background: #F7E49C;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thirdHLIP {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.5vw;
  align-items: center;
}

.publicPostAtHomePage {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.publicPostHeader {
  display: flex;
  align-items: center;
}

.PPHimgThumbnail img {
  object-fit: cover;
  width: 4vw;
  object-position: top;
  border-radius: 2.667px;
  height: 4vw;
}

.PPHimgThumbnail {
  margin-right: 1vw;
}

.PPHTime {
  color: rgba(0, 0, 0, 0.48);
  font-size: 1.2vw;
}

.PPHname {
  font-size: 1.2vw;
}

.PPHname:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nameATime {
  display: flex;
  gap: 0.5vw;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.dropDownPPH {
  margin-left: auto;
  position: relative;
}

.popupforviewmore {
  background-color: white;
  padding: 1vw;
  position: absolute;
  width: 13vw;
  z-index: 1;
  font-size: 1.3vw;
  top: 0;
  left: 2vw;
}

.popupforviewmore div {
  cursor: pointer;
  margin-top: 1vw;
}

.popupforviewmore div:nth-child(1) {
  color: red;
  margin-top: 0;
}

.HeadlinePPH {
  color: rgba(0, 0, 0, 0.64);
  font-size: 1vw;
}

.mainBodyImg {
  position: relative;
}

.mainBodyImg .arrowleftbl22 {
  position: absolute;
  top: 50%;
  left: 1%;
  background-color: rgba(255, 204, 0, 0.5);
  /* padding: 2vw; */
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.mainBodyImg .arrowrightbl22 {
  position: absolute;
  top: 50%;
  right: 1%;
  background-color: rgba(255, 204, 0, 0.5);
  /* padding: 1vw; */
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}


.carouser {
  width: 100%;
  display: flex;
  overflow: hidden;

}

.carouser img {
  width: 40vw;
  height: 60vh;
  flex-basis: 40vw;
  flex-shrink: 0;
  object-fit: cover;
}

.carouser video {
  width: 40vw;
  height: 60vh;
  flex-basis: 40vw;
  flex-shrink: 0;
  object-fit: cover;
}


.descriptionPPh.yesImg {
  font-size: 1.2vw;
  color: rgba(0, 0, 0, 0.616);
}

.descriptionPPh.noimg {
  font-size: 1.4vw;
  color: rgb(0, 0, 0);
}

.descriptionPPh p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.PPHCTA {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.PPHCTA1 {
  display: flex;
  gap: 1vw;
}

/* .opp {
  opacity: 0.7 !important;
} */

.PPHCTA1 .opp {
  width: 2.3vw;
  height: 2.3vw;
}



.oopsOneIssue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bannersForHomePage {
  width: 89%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bannersForHomePage2 {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 3vw;
}

.bannersForHomePage2 .box22 {
  left: 57% !important;
}

.first-divingkksk {
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
}

.cover-imgingkksk {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.arrowleftbl1 {
  background: #F7E49C !important;
  border-radius: 50% !important;
  width: 3vw !important;
  height: 3vw !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 21 !important;
  position: absolute;
  top: 40% !important;
  border: none !important;
  outline: none !important;
  left: -0.6px;
}

.arrowrightbl2 {
  background: #F7E49C;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 21;
  position: absolute;
  top: 40% !important;
  border: none !important;
  outline: none !important;
  right: 0;
}

.carouseldIn3HLIP {
  /* height: 60vh; */
  /* transform: translateY(-50%); */
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
  z-index: 230;
  width: 90%;
  cursor: pointer;
}

.carousel1 {
  transform: translateY(-50%);
  width: 80%;
  align-self: center;

}

.carousel1 .react-multi-carousel-list {
  height: 100%;
  gap: 30px;
  width: 927px;
  padding-left: 3.6vw !important;
}


.carousel1 .react-multi-carousel-item {
  width: 307px !important;
}




.imgForLCLHLIP {
  width: 100%;
  position: relative;
  height: 138px;
}

.imgForLCLHLIP .jsjsj {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.carouseldIn3HLIP .title {
  color: #000;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.16px;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 0.5vw;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.16px;
  width: 225px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.carouseldIn3HLIP .price {
  color: #000;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 0.5vw;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
}

.nameRatingTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 1vw;
  color: rgba(0, 0, 0, 0.56);

  /* Desktop/P3 Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  width: 223px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.ratingTime {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  width: max-content !important;

}

.apcta {
  background: #000;
  color: white;
  font-size: 1.4vw;
  padding: 0.6vw 1vw;
  text-align: center;
}

.FirstFilter {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  /* 108.333% */
  letter-spacing: -0.48px;
}

.secondFiltet {
  height: max-content;
  display: flex;
  width: 100%;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.48);
  margin-top: 30px;
  padding: 10px 12px;
  align-items: center;
  cursor: pointer;
}

.sf1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
}

.SFF1 {
  color: #000;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.SFF2 {
  color: #000;

  text-align: center;
  /* Desktop/P3 Medium */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.sf2 {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.h000 {
  height: 0px !important;
  transition: height 0.3s ease-in-out;
  overflow: hidden;

}

.secondFilterDropper {
  border-radius: 2px;
  background: var(--Primary-Base-White, #FFF);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
  display: flex;
  width: 230px;
  padding: 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: height 0.3s ease-in-out;
  height: max-content;
  position: absolute;
  top: 43%;
  left: -0.3vw;
}

.secondFilterDropper span {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
}

.thirdFilterGroup .headTFGG,
.fourthFilterGroup .headTFGG {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 130% */
  letter-spacing: -0.4px;
  margin-top: 30px;
}

.thirdFilterGroup .bodyTFGG {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 14px;
}

.bodyTFGGContent {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cuzNeedCls {
  display: flex;
  justify-content: space-between;
  padding-right: 5vw;
  align-items: center;
}

.opened {
  transform: rotate(180deg);
}

.checkBoxAtLearn {
  color: rgba(0, 0, 0, 0.72);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  display: flex;
  gap: 0.8vw;
  margin-top: 15px;
  margin-bottom: 0;
  align-items: center;
}

.checkBoxAtLearn input {
  width: 16px;
  height: 16px;
}

.carouseldIn3HLIP {
  display: flex;
  width: 241px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  gap: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
}

.sab1 {
  width: 360px;
  height: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.sab1 .img1 {
  filter: brightness(60%);
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.sab1 .img2 {
  position: absolute;
  top: 30%;
  left: 30%;
  width: 125px;
  height: 125px;
  scale: 1;
  transition: scale 0.3s ease-in-out;
  display: block;
}

.sab1:hover .img2 {
  scale: 100;
  pointer-events: none;
}

.sab1:hover .img1 {
  filter: brightness(100%);
}

.sab1 .img2:hover {
  scale: 1;
}

.sab2Text {
  display: flex;
  width: 360px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: #FFF;
  transform: translateY(-100%);
}

.streamAwesomeBox {
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
  width: max-content;
  width: 360px;
  height: 360px;
}

.nameOfTielsab2 {
  color: #000;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.16px;
}

.ratingNamehahaah {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.namehahaah {
  color: rgba(0, 0, 0, 0.56);

  /* Desktop/P3 Medium */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.ratingHahaah {
  color: rgba(0, 0, 0, 0.56);

  /* Desktop/P3 Medium */
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.SSFHP1 {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.streamingSectionFromHomePage {
  display: flex;
  gap: 2vw;
}

.tabInFriends {
  display: flex;
  flex-direction: column;
  margin-left: 79px;
  margin-top: 25vh;
  gap: 10px;
}

.allF,
.suggestionWasted {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #171A1F;
  /* Desktop/P2 Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  width: 181px;
  cursor: pointer;
}

.clickedOnAfriendTab {
  background-color: #FFCB00;
}

.friend {
  display: flex;
}

.headingOfAFTIF {
  display: flex;
  gap: 60px;
  border-bottom: 1px solid #D9D9D9;
  ;
  width: 751px;
  height: 42px;
}

.AFTIF {
  margin-left: 95px;
  margin-top: 20vh;
}

.HOAFTIFS {
  border-bottom: 3px solid #FFCB00;
}

.HOAFTIF1,
.HOAFTIF2 {
  width: 108px;
  height: 42px;
  text-align: center;
  cursor: pointer;
}

.instructionAFTIF {
  color: #171A1F;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
}

.friendGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 50px;
}

.friendGrid2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  /* margin-top: 20px; */
  width: 930px;
}

.imgInFG {
  width: 165px;
  height: 165px;
  border-radius: 2px;
  background: #D9D9D9;
}

.nameAndOptionInFG {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  width: 165px;
  margin-top: 10px;
}

.nameInFgi {
  color: #171A1F;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.buttonForFollowBack {
  border-radius: 2px;
  background: #000;
  width: 100%;
  text-align: center;
  color: white;
  padding: 4px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  margin-top: 12px;
}

.sideStrollerMp {
  position: fixed;
  height: 88vh;
  width: 48px;
  background-color: #000;
  left: 0;
  transition: width 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 1vw;
  padding-top: 3vw;
  padding-left: 1vw;
  z-index: 999;
}

.sideStrollerMp:hover {
  width: 250px;
}

.juhugulu {
  width: 12.607px !important;
  height: 12.607px !important;
}

.SSMP {
  display: flex;
  gap: 1.5vw;
  width: max-content;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  align-items: center;
  justify-content: center;
}

.SSMP img {
  width: 17.971px;
  height: 17.971px;
}

.imgAdddhahahahhaa {
  position: relative;
}

.imgAdddhahahahhaa input {
  position: absolute;
  display: none !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.emojiPublicPost aside {
  position: absolute !important;
  z-index: 1000000;
  left: -600%;
  top: -300%;
}

.emojiPublicPost {
  width: 20px !important;
  height: 29px !important;
  position: relative;
}

.ql-editor {
  font-family: "Noto Color Emoji", sans-serif;
}

.imgAndVidPreview {
  display: flex;
  gap: 1vw;
  margin-top: 3vw;
}

.imgAndVidPreview img {
  width: 10vw;
  height: 20vh;
  object-fit: cover;
}

.imgAndVidPreview video {
  width: 10vw;
  height: 20vh;
  object-fit: cover;
}

.imgAndVidPreview div {
  position: relative;
}

.imgAndVidPreview span {
  position: absolute;
  top: 0.25vw;
  right: 0.5vw;
}

.imgAndVidPreview span:hover {
  color: #FFCC00;
  cursor: pointer;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}

.caro {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 14vh;
}

.slideimg {
  object-fit: cover !important;
  object-position: center !important;
}

.allTheBannerLogOut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bannersForHomePage2 {
  width: 83%;
}

.impEditButton {
  position: absolute;
  background-color: #000;
  color: white;
  top: 16vh;
  left: 70vw;
  padding: 0.5vw 1vw;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.profilePageForMe {
  background-color: #f5f5f4;
  margin-top: 13vh;
  display: flex;
  align-items: center;
  gap: 5vw;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.dpAtPPM img {
  width: 14vw;
  height: 14vw;
}

.userDetail .name {
  font-size: 2.5vw;
}

.followingFollowers {
  display: flex;
  gap: 2vw;
  font-size: 1.25vw;
  color: #8E8E8D;
  margin-top: 0.1vw;
}

.userDetail .userDescription {
  font-size: 1.1vw;
  margin-top: 0.5vw;
}

.profileOptionsInMp {
  margin-top: 1vw;
  display: flex;
  color: #8E8E8D;
  font-size: 1.1vw;
  gap: 3vw;
}

.profilePageForMe .userDetail {
  gap: 0.5vw;
  display: flex;
  flex-direction: column;
}

.tabsInPPFM {
  display: flex;
  gap: 3vw;
  margin-right: 15vw;
  margin-left: 15vw;
  border-bottom: 1px solid black;
  padding: 1vw;
  font-size: 1.5vw;
  color: #8E8E8D;
  padding-bottom: 0;
  padding-left: 0;
}

.tabsInPPFM div {
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  height: 100%;
  padding-bottom: 0.8vw;
}

.selectedTabInPPFM {
  border-bottom: 3px solid black;
  color: black;
}

.jokinglyAwesomeNoClasses {
  font-size: 3vw;
  text-align: center;
  width: 100% !important;
  display: block;
  margin-top: 10vw;
  margin-bottom: 10vw;
}

.girdInCTIPPFM {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 15vw;
  padding-right: 15vw;
  gap: 0.5vw;
  padding-top: 2vw;
  min-height: 80vh;
}

.classItemInGICTIPPFM {
  height: 50vh;
  width: 16vw;
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.imgParentDiv img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.descrCIIGICTIPPFM {
  display: flex;
  gap: 1vw;
  padding-top: 1vw;
}

.DCIIGICTIPPFM1 {
  width: fit-content;
}

.DCIIGICTIPPFM1 img {
  width: 3vw;
  height: 3vw;
  object-fit: cover;
  border-radius: 50%;
}

.DCIIGICTIPPFM2 {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  gap: 0.25vw;
}

.DCIIGIclassName1 {
  font-size: 1.1vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  color: #606060;
}

.DCIIGIProfileName1 {
  font-size: 0.8vw;
  color: #888787;
}

.postsHave {
  display: flex;
  padding-top: 3vw;
  padding-left: 15vw;
  padding-right: 15vw;
  gap: 5%;
}

.postInohhhhhhTheProfile {
  width: 40%;
}

.identityInPosts {
  width: 100%;
  background-color: #f5f5f4;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  color: #8E8E8D;
  padding: 1vw 1.5vw;
  border-radius: 15px;
  font-size: 1vw;
}

.identityInPosts .heaad {
  color: #000;
  padding-bottom: 1.5vw;
  border-bottom: 1px solid #8E8E8D;
  font-size: 1.3vw;

}

.classesJoineInPosts {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
}

.classesJoineInPosts .heeaad {
  font-size: 1.3vw;
}

.classesJoineInPosts .body {
  font-size: 1.5vw;
  padding: 0.5vw 1vw;
  background-color: #f5f5f4;
  text-align: center;
  width: fit-content;
  align-self: center;
  margin-top: 3vw;
  border-radius: 10px;
  margin-bottom: 3vw;
}

.friendsInPosts {
  display: flex;
  flex-direction: column;
}

.friendsInPosts .heeaad {
  font-size: 1.3vw;
  margin-bottom: 0.5vw;
}

.friendsInPosts .body {
  font-size: 1.5vw;
  padding: 0.5vw 1vw;
  background-color: #f5f5f4;
  text-align: center;
  width: fit-content;
  align-self: center;
  margin-top: 3vw;
  border-radius: 10px;
  margin-bottom: 3vw;
}

.mmiiimm {
  font-size: 0.9vw;
  color: #606060;
}

.publicPostsFromProfilep {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 55%;
  gap: 2vw;
}

.noPublicPostInPPFP {
  font-size: 3vw;
}

.postPublicInProfile {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

.PPIPPrdescr {
  color: #606060;
  font-size: 1.1vw;
  width: 100%;
  background-color: #f5f5f4;
  padding: 0.85vw 1.25vw;
  line-height: 1.3vw;
  height: fit-content;
}

.PPIPPrdescr p {
  margin-bottom: 0 !important;
}

.postPublicInProfile .immmmmmmmmmmmmmmmg img {
  width: 100%;
  height: 45vh;
  object-fit: cover;
}

.shareDare {
  display: flex;
  padding: 0.8vw 1.3vw;
  background-color: #f5f5f4;
  font-size: 1vw;
  justify-content: space-between;
}

.shareDare .s2 {
  border: 1px solid black;
  padding: 0.25vw 0.75vw;
}

.infoOfAbout {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1vw;
}

.infoOfAbout .grouppingInIOA {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}

.infoOfAbout .grouppingInIOA div {
  /* border: 1px solid #606060; */
  width: 50%;
  padding: 0.45vw 0.95vw;
  font-size: 1.1vw;
  border-radius: 4px;
  overflow-x: scroll;
  white-space: nowrap;
  text-wrap: nowrap;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.noGrouppingInIOA {
  border: 1px solid #606060;
  width: 100%;
  padding: 0.45vw 0.95vw;
  font-size: 1.1vw;
  border-radius: 4px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.interestsInProfile .heeeeeeeeeeaaaaaaaaadddddd {
  font-size: 1.5vw;
}

.interestsInProfile {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.interestsInProfile .boddddddddyyyyyyyyyy {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
}

.interestsInProfile .boddddddddyyyyyyyyyy div {
  width: fit-content;
  padding: 0.25vw 0.5vw;
  border: 1px solid black;
  background-color: #FBF9F1;
  border-radius: 5px;
}

.gapBwFootAndPost6351 {
  height: 3vw;
}

.optionsPOIMP {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3vw;
  cursor: pointer;
}

.aboutProfile {
  padding-left: 48px;
  margin-top: 12vh;
}

.aboutProfile2 {
  padding-left: 8px;
  margin-top: 12vh;
}

.backBannerInProfile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 288px;
  position: relative;
}

.backBannerInProfile img {
  width: 98%;
  height: 100%;
  object-fit: cover;
}

.theProfilePic {
  display: flex;
  width: 230px;
  height: 230px;
  justify-content: center;
  align-items: center;
  transform: translateY(-28px);
  border-radius: 7.667px;
  position: relative;
}

.theProfilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 7.667px; */
  border-radius: 3px;
}

.userDetails {
  margin-left: 78px;
  display: flex;
}

.teacherIsHere {
  border: 4.6px solid #FFCB00;
  border-radius: 7.667px;
}

.tagOfUser {
  position: absolute;
  z-index: 2;
  font-size: 11.04px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.56px;
  /* 150% */
  letter-spacing: 0.221px;
  text-transform: uppercase;
  background-color: #F7E49C;
  padding: 7.36px 14.72px;
  border-radius: 1.84px;
  border: 3.68px solid #FC0;
  bottom: -25px;
}

.userAddressDet {
  display: flex;
  gap: 15px;
}

.checktheUserName {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.textNextToDp {
  display: flex;
  margin-left: 30px;
  margin-top: 40px;
  gap: 55px;
  align-items: center;
}

.bbName {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  /* 106.25% */
  letter-spacing: -0.64px;
}

.userAddressDet {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.userAddressDet span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.shareProfilejjjkkk {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  border-radius: 2px;
  background: #000;
  padding: 8px 24px;
  color: white;
}

.followHollowJollow {
  margin-top: 30px;
  display: flex;
  margin-left: 31px;
  gap: 50px;
}

.infooo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;

}

.infooo span:nth-child(1) {
  color: rgba(0, 0, 0, 0.32);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 111.111% */
  letter-spacing: -0.72px;
}

.infooo span:nth-child(2) {
  color: rgba(0, 0, 0, 0.48);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.tabsforProfilePageJJsn {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-left: 140px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  gap: 30px;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.joonie {
  border-bottom: 3px solid #FFCB00;
  height: 100%;
  padding-bottom: 15px;
}

.columbusProfile {
  background: #F7F7F7;
}

.aboutProfileJKKKLI {
  display: flex;
  gap: 35px;
  padding-left: 140px;
  padding-right: 185px;
}


.aboutProfileJKKKLI .aboutBox {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #ffffff;
  width: 680px;
  gap: 30px;
}

.aboutFlex {
  display: flex;
  justify-content: space-between;
}

.aboutFlex .ogreeeeee {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 130% */
  letter-spacing: -0.4px;
}

.aboutFlex img {
  width: 11.98px;
  height: 11.98px;
}

.aboutBox2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.aboutBox2 .ogre2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
  letter-spacing: -0.18px;
}

.aboutBox2 .ogre2Date {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.gireder {
  padding: 10px 24px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.48);
  color: rgba(0, 0, 0, 0.64);
}

.girederWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.PYMK {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #ffffff;
  width: 355px;
  gap: 30px;
}

.PYMK .head {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 130% */
  letter-spacing: -0.4px;
}

.PYMK .descr {
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.zoronogaworokoworo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.PYMK .people {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.PYMK .people .box {
  width: 50px;
  height: 50px;
  background: #D9D9D9;
}

.PYMK .people .nameTagFollowFPYMK {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.nameTagFollowFPYMK .nametagPYMK {
  display: flex;
  flex-direction: column;
}

.namePYMK {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.tagPYMK {
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
}

.followPYMK {
  padding: 6px 12px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.16px;
  border-radius: 2px;
  border: 1px solid #000;
}

.gapperrappersnapper {
  height: 30px;
}

.editBox {
  position: fixed;
  top: 15vh;
  z-index: 2132456;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editForum {
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 2vw;
  gap: 0.5vw;
  height: 80vh;
  border-radius: 10px;
  overflow-y: auto;
  flex-shrink: none;
}

.editForum span {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.18px;
  margin-top: 0.5vw;
}


.edit1title {
  display: flex;
  justify-content: space-between;
}

.editForum textarea {
  width: 100%;
  height: 10vh;
  border-radius: 5px;
  outline: none;
  flex-grow: 1;
  min-height: 10vh;
}

.editForum input {
  border-radius: 5px;
  outline: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.507);
}

.no {
  display: flex;
  justify-content: center;
  align-items: center;
}

.classDropperInProfiler {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  font-size: 14px;
  color: white;
  margin-top: -0.5vw;
  padding-left: 3vw;
}

.classDropperInProfiler span {
  cursor: pointer;
}

.jsaojasojdasdas {
  height: 13vh;
  display: block;
}

.headdddd123 {
  margin-left: 8vw;
  font-size: 2vw;
  margin-top: 4vw;
}

.classInfo123 {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 685px;
  padding: 2vw;
  margin-bottom: 2vw;
}

.classTableInProfile123 {
  margin-left: 8vw;
  display: flex;
  gap: 25px;
}

.classInfo123 .title {
  font-size: 1.3vw;
  text-align: center;
}

.classInfo123 .tabsssssss {
  display: flex;
  justify-content: space-around;
  margin-top: 1vw;
}

.classInfo123 .tabsssssss span {
  border-bottom: 4px solid #0000004d;
  padding: 0 3vw;
  padding-bottom: 1vw;
  font-size: 1.2vw;
  color: #0000004d;
  cursor: pointer;
}

.jimeronimerodinero {
  border-bottom: 4px solid #FFCB00 !important;
  color: black !important;
}

.classesssssssss123 {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.title123 {
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.aisdnirnvcidsa {
  position: absolute;
  right: -15%;
  top: 0%;
  background-color: white;
  padding: 1vw;
  color: red;
  cursor: pointer;
}



.schedule {
  color: #0000009f
}

.details123 {
  display: flex;
  gap: 20px;
  margin-top: 2vw;
}

.details123 .infroooo {
  background-color: #00000017;
  width: 140px;
  height: 102px;
  display: flex;
  flex-direction: column;
  padding: 0.8vw;
  justify-content: space-around;
}

.details123 .infroooo span:nth-child(1) {
  font-size: 32px;
  color: #00000073;
}


.details123 .infroooo span:nth-child(2) {
  font-size: 14px;
  color: #00000073;
}


.jiggerniggertriggerfirgger {
  font-size: 2vw;
  text-align: center;
  margin-top: 2vw;

}


.terainerData {
  padding: 30px;
  background-color: white;
  width: 360px;
  margin-bottom: 2vw;
}

.terainerData span:nth-child(1) {
  font-size: 1.5vw;
}

.terainerData .adhiahdieaadf {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 2vw;
}

.asffrpaerrngfd {
  font-size: 3vw !important;
}

.asorothifbass {
  font-size: 1.3vw;
}

.asiopoiuhgfds {
  display: flex;
  flex-direction: column;
  background-color: #f7e39c81;
  padding: 1vw;
}

.newoahdhidsf {
  display: block;
  color: #000000c7;
}

.wishlistIconocs {
  margin-left: 10vw;
  margin-top: 4vw;
  font-size: 3vw;
  margin-bottom: 5vw;
}

.head10224 {
  font-size: 2vw;
  margin-left: 10vw;
  margin-top: 3vw;
  margin-bottom: 1vw;
}

.tabs10223 {
  padding-left: 10vw;
  display: flex;
  gap: 4vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.192);
}

.tabs10223 span {
  padding-bottom: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  cursor: pointer;
}

.selectedDelected {
  border-bottom: 4px solid #FFCB00;
}

.asdjoarrbgfiasfsf {
  text-align: center;
  margin-top: 10vw;
  margin-bottom: 10vw;
  font-size: 2vw;
}

.privacy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
  flex-direction: column;
  gap: 3vw;
  margin-bottom: 4vw;
}

.passChangerPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  display: flex;
  justify-content: center;
  align-items: center;
}

.passChangerPop .formmm {
  width: 50vw;
  height: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding: 2vw;
  border-radius: 5px;
}

.passChangerPop .formmm .flexxyyInput {
  display: flex;
  gap: 0.5vw;
  font-size: 1.5vw;
  align-items: center;
}

.passChangerPop .formmm .buttokenns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5vw;
}

.passChangerPop .formmm .buttokenns .cancellller {
  background-color: transparent;
  border: 1px solid black;
  padding: 0.5vw 1vw;
  cursor: pointer;
  border-radius: 5px;
}

.passChangerPop .formmm .buttokenns .saverrrrr {
  background-color: black;
  color: white;
  padding: 0.75vw 1.5vw;
  cursor: pointer;
  border-radius: 5px;
}

.passChangerPop .formmm .flexxyyInput input {
  padding: 0.5vw;
  border-radius: 5px;
  outline: none;
  border: none;
  border: 1px solid rgba(128, 128, 128, 0.452);
}

.privacyDiv {
  width: 620px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.headbecauseProfile {
  margin-bottom: 15px;
  font-size: 2vw;
}

.privacy .passChanger {
  font-size: 1.5vw;
  width: 620px;
  border-top: 1px solid rgba(128, 128, 128, 0.664);
  padding-top: 2vw;
  cursor: pointer;

}

.privacy .passChanger:hover {
  text-decoration: underline;
}

.secondheadbecauseProfile {
  display: flex;
  justify-content: space-between;
  font-size: 1.5vw;
}

.asdasdasdassdassd {
  margin-bottom: 10px;
  font-size: 1.2vw;
}

.classesinProfileOfOther {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-left: 10vw;
  margin-bottom: 10vw;
}

.classesinProfileOfOther .heaaaaaaaaaad {
  font-size: 22px;
}

.wowAwesomeText {
  font-size: 15px;
  margin-top: 10px;
}

.sideStrollerStream {
  top: 13vh;
  background-color: #f7f7f7;
  height: 87vh;
  position: absolute;
  padding: 30px 20px;
}

.headInfo {
  color: #000000d0;
  text-transform: uppercase;
  font-size: 14px;
}

.aoajdoeneccd {
  top: 30vh;
}

.orocoomaojs {
  display: block;
  font-size: 24px;
}

.aojojfidasdasrgsrre {
  margin-top: 15vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 84px;
  padding-right: 84px;
}

.butooonssfisdhf {
  font-size: 20px;
  padding: 10px 50px;
  background-color: black;
  color: white;
}

.carouselOfStream {
  position: relative;
  margin-top: 14vh;
  /* margin-left: 20vw;
  width: 1075px;
  height: 469px; */
  overflow: hidden;
  display: flex;
}

.joashdddddrfgdsa {
  display: flex;
}

#title {
  outline: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.281);
}

.headd6kahsd {
  font-size: 18px;
  /* margin-left: 20vw; */
  text-align: center;
  display: block;
  margin-top: 5vw;
}

.boddy6kahsd {
  margin-left: 20vw;
  display: block;
  width: 975px;
  text-align: center;
  height: 869px;
  background-color: #f7f7f7;
  margin-top: 1vw;
  overflow: hidden;
}

.neoTheCorrect {
  width: 600px;
  height: 400px;
  object-fit: cover;
}

.swiper_container {
  width: 122%;
  transform: translateX(-229px);
}

.swiper-button-prev {
  transform: translateX(225px);
  background-color: #F7E49C;
  padding: 1vw 1.5vw;
  border-radius: 50%;

}

.swiper-button-next {
  background-color: #F7E49C;
  padding: 1vw 1.5vw;
  border-radius: 50%;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}


.StreamsShowing {
  display: flex;
  width: 975px;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 30px;
  /* margin-left: 20vw; */
  margin-top: 4vw;
  flex-wrap: nowrap;
  flex-shrink: none;
  height: max-content;
  align-items: center;

}

.StreamsShowing::-webkit-scrollbar {
  display: none;
}

.StreamsShowing .streamCard {
  width: 338px !important;
  height: 380px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.StreamsShowing .streamCard .img img {
  width: 338px;
  object-fit: cover;
  height: 312px;
}

.StreamsShowing .streamCard .img {
  width: 338px;
  position: relative;
}

.StreamsShowing .streamCard .descrCard {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
}

.StreamsShowing .streamCard .descrCard .nameTitle {
  font-size: 20px;
}

.StreamsShowing .streamCard .descrCard .nameAndView {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding-bottom: 0.5vw;
}

.StreamsShowing .streamCard .descrCard .nameAndView .view {
  display: flex;
  gap: 0.5vw;
}

.StreamsShowing .streamCard .img .liveTag {
  position: absolute;
  top: 1vw;
  left: 1vw;
  font-size: 14px;
  background-color: #FFCB00;
  padding: 0.5vw 1vw;
}

.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  font-size: 12px;
}

.wishIconHahahaha:hover .tooltip {
  visibility: visible;
  top: 100%;
  /* Adjust the top position below the cursor */
  left: 50%;
  transform: translateX(-50%);
}

.wishIconHahahaha:hover::-ms-tooltip {
  content: 'Add to wishlist';
}

.hamburgerrr {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  gap: 2vw;
  z-index: 22222;
  background-color: white;
  width: 25vw;
  height: 100vh;
  top: 0;
  padding-top: 3vw;
  padding-left: 2vw;
  transition: all 1s ease-in-out;
}


.inactHamb {
  right: -25vw;
}



.hamburgerrr div {
  cursor: pointer;
}

.reportedThePost {
  position: fixed;
  background-color: white;
  color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 2vw;
  font-size: 1.7vw;
  left: 15%;
  top: 50%;
  z-index: 99;
}

.reportedThePost .closer {
  align-self: flex-end;
  margin-top: -1vw;
  margin-bottom: -0.5vw;
}

.share-options {
  background-color: white;
  position: absolute;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  /* align-items: center; */
  padding: 1vw;
  font-size: 1.2vw;
  color: black !important;
  height: fit-content;
}

.share-options {
  background-color: white;
  position: absolute;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  /* align-items: center; */
  padding: 1vw 1.3vw;
  font-size: 1.3vw;
  color: black !important;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1vw;
}

.share-options a {
  color: black !important;
  cursor: pointer;
}

.share-options img{
  width: 1.3vw;
}

.share-options div {
  color: black !important;
  cursor: pointer;
}

.share-options2 {
  background-color: white;
  position: absolute;
  bottom: 150%;
  left: -100%;
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  /* align-items: center; */
  padding: 1vw;
  font-size: 1.2vw;
  color: black !important;
}

.share-options2 a {
  color: black !important;
  cursor: pointer;
}

.share-options2 div {
  color: black !important;
  cursor: pointer;
}

.c2ko {
  position: absolute;
  bottom: 3vw !important;
  left: 3vw !important;
  width: max-content;
  padding: 0 !important;
  text-align: left !important;
  color: white !important;
  font-size: 4.5vw !important;
  line-height: 4.7vw;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}

.c2ko button {
  font-size: 1.4vw !important;
  background-color: white !important;
  color: black;
  outline: none;
  border: none;
  padding: 1vw 2vw;
  line-height: 1.5vw;
  border-radius: 10px;
}

.boxStreamS {
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
  width: max-content;
  width: 360px;
}

.boxStreamS .infoo {
  background-color: white;
  padding: 1.5vw 1vw;
}

.boxStreamS .butt {
  background-color: #f7e39c46;
  padding: 1vw 1vw;
}

.carouselOfStream .streamPreview {
  width: 50vw;
  height: 50vh;
  position: relative;
}

.carouselOfStream .streamPreview video {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
  background-color: #000;
}

.carouselOfStream .streamPreview img {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
}

.carouselOfStream .streamListPrev {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #000;
  color: white;
}

.carouselOfStream .streamListPrev .cards {
  display: flex;
  gap: 1vw;
  padding: 1.5vw;
  align-items: center;
  border-top: 1px solid white;
}

.wishGrid {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 2vw;
}

.carouselOfStream .streamListPrev .cards .imgofp img {
  width: 6vw;
  height: 6vw;
  object-fit: cover;
  border: 0.5px solid white;
}

.carouselOfStream .streamListPrev .cards .stDet {
  display: flex;
  flex-direction: column;
}

.carouselOfStream .streamListPrev .cards .stDet .titleOfSt {
  font-size: 1.3vw;
}

.carouselOfStream .streamListPrev .cards .stDet .detStrV {
  font-size: 1vw;
  display: flex;
  align-items: center;
}

.carouselOfStream .streamListPrev .cards .stDet .detStrV div {
  padding: 0.5vw;
  display: flex;
  align-items: center;
  gap: 0.3vw;
}

.carouselOfStream .streamListPrev .current {
  background-color: #FFCC00;
}

.carouselOfStream .streamListPrev .cards .stDet .detStrV .viewwws {
  border-right: 1px solid #00000081;
  height: 1vw;
}

.carouselOfStream .streamPreview .smallBanner {
  position: absolute;
  top: 1vw;
  left: 1vw;
  background-color: #FFCC00;
  font-size: 1vw;
  padding: 0.5vw;
  border-radius: 3px;
}

.searchsuggestion {
  position: absolute;
  top: 120%;
  background-color: white;
  width: 100%;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.searchsuggestion .head {
  color: #00000083;
  font-weight: 500 !important;
}

.searchPageForuser {
  margin-top: 16vh;
}

.searchPageForuser .allResult {
  min-width: 70vw;
}

.searchPageForuser .seracher {
  margin-left: 2vw;
}

.searchPageForuser .seracher input {
  border: none;
  outline: none;
  border: 1px solid #0000002c;
  font-size: 1.5vw;
  padding: 0.5vw 0.7vw;
  width: 30vw;
}

.searchPageForuser .resulterWithf {
  display: flex;
  margin-left: 2vw;
}

.searchPageForuser .seracher input:focus {
  border: 1px solid #00000063;
}

.searchPageForuser .profileItem {
  display: flex;
  flex-direction: column;
  height: 199px;
  width: 165px;
  font-size: 1vw;
}

.searchPageForuser .profilerrr .head {
  margin-bottom: 1vw;
}

.searchPageForuser .profileItem .imgggg {
  width: 165px;
  height: 165px;
}

.searchPageForuser .profileItem .imgggg img {
  width: 165px;
  height: 165px;
  object-fit: cover;
}

.searchPageForuser .Result {
  margin-top: 6vw;
  margin-left: 3vw;
}

.searchPageForuser .profileItem .nameeeee {
  font-size: 1vw;
  display: flex;
  align-items: center;
  height: 34px;
}

.searchPageForuser .classerrr {
  margin-top: 3vw;
}

.searchPageForuser .classerrr .gridderForCourse {
  margin-left: 0 !important;
  margin-top: 1.5vw;
  font-size: 1.4vw;
}

.searchPageForuser .posterrr {
  margin-top: 3vw;
}

.searchPageForuser .postsShouldBeCetn {
  display: flex;
  flex-direction: column;
  padding: 10vw;
  padding-top: 3vw;
  gap: 2vw;
  width: 100%;
  font-size: 1.4vw;
  text-align: left;
}

.allResult .profilerrr .listt {
  font-size: 1.4vw;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  gap: 3vw;
}

.ratingForClasses {
  margin-top: 13vh;
}

.ratingForClasses .BannerForRFC {
  background-color: #F7E49C;
  font-size: 2vw;
  text-align: center;
  padding: 3vw;
}

.ratingForClasses .rater {
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5vw;
}

.ratingForClasses .starsInthesky {
  width: 50vw;
  display: flex;
  gap: 2vw;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
}

.ratingForClasses .starsInthesky .stt .st {

  transition: 0.3s all ease-in;
}

.ratingForClasses .starsInthesky .stt {
  width: 3vw;
  height: 3vw;
}

.ratingForClasses .starsInthesky .selectedStar .st {
  fill: #FFCB00;
}

.starDescr {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.48);
  display: flex;
  width: 24vw;
  justify-content: space-between;
  margin-top: 0.5vw;
}

.rater .textBox textarea {
  resize: none;
  border: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.24);
  color: rgba(0, 0, 0, 0.726);
  padding: 1vw;
  margin-top: 3vw;

}

.rater .buttons {
  display: flex;
  width: 50vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vw;
  margin-bottom: 3vw;
}

.rater .buttons span:nth-child(1) {
  font-size: 1.3vw;
  color: #0000009f;
  cursor: pointer;
}

.rater .buttons span:nth-child(2) {
  font-size: 2vw;
  background-color: #000;
  color: white;
  padding: 0.5vw 2vw;
  cursor: pointer;
}

.rater .buttons .disable {
  background-color: #2e2e2e !important;
  cursor: initial !important;
}

.psdppsdp {
  padding: 0 !important;
  margin-top: 1vw;
}

.readMore {
  position: absolute;
  color: rgb(0, 0, 0);
  bottom: 0.5vw;
  right: 1vw;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.1s all ease-in;
}


.readMore:hover {
  color: #FFCB00;
}

.allRev {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allRev .clickOut {
  background-color: #00000065;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.allRev .lllsld {
  width: 70vw;
  height: 90vh;
  background-color: white;
  position: fixed;
  z-index: 34567654;
  display: flex;
  border-radius: 15px;

}

.allRev .lllsld .summary {
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-top: 5vh;
  flex-direction: column;
  border-right: 5px solid rgba(0, 0, 0, 0.589);
}

.allRev .lllsld .summary .average {
  font-size: 6vw;
}

.allRev .lllsld .summary .titllleee {
  font-size: 2vw;
}


.allRev .lllsld .reviewList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 50vw;
  margin-top: 5vh;
  font-size: 4vw;
}

.lovoovovoovovov {
  position: absolute;
  width: 20vw;
  bottom: 1vw;
  left: 25vw;
}

.isfh {
  background-color: #ffcc00b2;
}

.boooookkkmmmaaarrrrkkkk {
  width: 1.5vw;
  object-fit: cover;
  height: 2.2vw;
}

.asdjoarrbgfiasfsf {
  width: 30vw;
  margin-left: 15vw;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  gap: 4vw;
}

.sab1 .img2 {
  position: absolute;
  top: 30%;
  left: 30%;
  width: 106px;
  height: 94px;
  scale: 1;
  transition: scale 0.3s ease-in-out;
  display: block;
}

.publicPostAtHomePage {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  width: 40vw;
}

.sab1 .img2 {
  position: absolute;
  top: 30%;
  left: 39%;
  width: 22%;
  height: 22%;
  scale: 1;
  transition: scale 0.3s ease-in-out;
  display: block;
}

.kkksdinsifnr {
  width: 1.5vw !important;
  height: 1.5vw !important;
  object-fit: cover;
  position: absolute;
  top: 1vw;
  right: 1vw;
  cursor: pointer;
}

.asfnirgnerineri {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  position: absolute;
  top: 1vw;
  right: 1vw;
  cursor: pointer;
  opacity: 0;
  cursor: pointer;
}

.aosjdojdfof {
  font-size: 3vw;
  margin-top: 5vw;
}

.oasjosjdosjdosdjosj {
  width: 89%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.closerTheButtonidinsds {
  font-size: 2vw;
  position: absolute;
  top: 1vw;
  right: 1vw;
}

.laslsaldlasllasdasasDSSA {
  margin-left: 45% !important;
}

.secondFilterDropper {
  border-radius: 2px;
  background: var(--Primary-Base-White, #FFF);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
  display: flex;
  width: 230px;
  padding: 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: height 0.3s ease-in-out;
  height: max-content;
  position: absolute;
  top: 19%;
  left: 17.7vw;
  z-index: 2;
}

.changeImageOrRemove {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 12345432;
}

.changeImageOrRemove .listter {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  font-size: 2vw;
  background-color: white;
  position: relative;
  padding: 1.5vw 3vw;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
}

.changeImageOrRemove .listter span:nth-child(2) {
  color: red;
}

.changeImageOrRemove .listter span:nth-child(3) {
  color: rgba(0, 0, 0, 0.397);
}

.changeImageOrRemove .listter span {
  cursor: pointer;
  position: relative;
}

.aosdnvfrinfcamdoemdffdsasf {
  position: absolute;
  bottom: 1vw;
  right: 2vw;
  width: 2vw !important;
  height: 2vw !important;
  border-radius: 50%;

}

.diahrbfvsaosorfgv {
  background-color: #FFCB00;
  color: white;
  padding: 0.5vw 1vw;
  font-size: 2vw;
  width: max-content;
  cursor: pointer;
}

.editForum input:not(:focus) {
  color: rgba(0, 0, 0, 0.486) !important;
  /* Change this to your desired color */
}


.editForum textarea:not(:focus) {
  color: rgba(0, 0, 0, 0.486) !important;
  /* Change this to your desired color */
}

.changeImageOrRemove {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 90%;
  z-index: 12345432;
  top: -1vw;
  left: 80%;
}

.changeImageOrRemove .listter {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  font-size: 1.3vw;
  background-color: white;
  position: relative;
  padding: 1vw 2vw;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
}

.streamEnterCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editForum {
  left: inherit;
  right: inherit;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 2vw;
  gap: 0.5vw;
  height: 90vh;
  border-radius: 10px;
  position: absolute;
  top: -9vh;
}

.carousel1 {
  transform: translateY(-50%);
  width: 100%;
  align-self: center;
  justify-content: center;
  display: flex;
}

.oasjosjdosjdosdjosj {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.streamingSectionFromHomePage {
  display: flex;
  gap: 2vw;
  width: max-content;
}

.bannersForHomePage3334 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;
}

.bannersForHomePage3334 .theBanner {
  width: 85%;
  display: flex;
  height: 68vh;
}

.bannersForHomePage3334 .theBanner .TBtext {
  width: 40%;
  background-color: #FFCB00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bannersForHomePage3334 .theBanner .TBtext .tllle {
  font-size: 4vw;
  line-height: 3.7vw;
}

.bannersForHomePage3334 .theBanner .TBtext .buttonosns {
  font-size: 1.4vw;
  color: white;
  background-color: black;
  padding: 0.75vw 1.25vw;
  margin-top: 2vw;
  align-self: flex-start;
  margin-left: 10%;
  cursor: pointer;
  padding: 1vw 2vw;
  line-height: 1.5vw;
  border-radius: 10px;

}


.bannersForHomePage3334 .theBanner .imgInClassBanner {
  width: 60%;
}

.bannersForHomePage3334 .theBanner .imgInClassBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c2ko button {
  font-size: 1.4vw !important;
  background-color: white !important;
}

.asifhdsbfdsfv {
  margin-left: 8vw !important;
}

.uploaderForHomePagePP {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #00000096;
  z-index: 213454543212345;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showingUplodingFile {
  background-color: white;
  width: 60%;
  height: 50%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  font-size: 2vw;
}

.theUploadedVer2{
  position: relative;
  width: 80%;
  height: 3vw;
  background-color: #00000196;
  border-radius: 50px;
}

.ClassroomFirst {
  margin-top: 6vw !important;
  display: flex;
  justify-content: space-between;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: #F7F7F7;
  align-items: center;
  position: relative;
}

.ClassroomFirst .textCF {
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.ClassroomFirst .textCF .headText {
  font-size: 3.5vw;
  line-height: 3.7vw;
  margin-bottom: 1vw;
}

.ClassroomFirst .textCF .bodyTextCf {
  font-size: 1.5vw;
  line-height: 1.8vw;
  height: 3.6vw;
  margin-bottom: 4vw;
}

.ClassroomFirst .textCF .buttonToSubCf {
  font-size: 1.8vw;
  background-color: black;
  color: white;
  padding: 0.8vw 1.6vw;
  width: fit-content;
  cursor: pointer;
  border-radius: 10px;
}

.ClassroomFirst .imgCF img {
  width: 40vw;
  object-fit: cover;
}

.ClassroomFirst .textCF::before {
  background-color: #ffcc00;
  width: 5vw;
  position: absolute;
  right: 0;
  height: 100%;
  content: '';
  top: 0;
}

.Cffbannertitle {
  width: 40%;
  font-size: 3.5vw;
  text-align: center;
  line-height: 4vw;
  margin-bottom: 2vw;
  line-height: 3.5vw;
  letter-spacing: -1px;
}

.noticeClassroomToShare {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  flex-direction: column;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.noticeClassroomToShare .linkerLiked {
  padding: 0.6vw 5.5vw;
  border: 1px solid black;
  font-size: 22px;
  cursor: pointer;
  position: relative;
}

.noticeClassroomToShare .nutokraate {
  font-size: 26px;
}

.indicatorCRT {
  display: flex;
  width: 60vw;
  margin: auto;
  margin-top: 8vw;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.wayToCrt2{
  width: -webkit-fill-available;
  height: 3px;
  background-color: #8080803d;
  position: relative;
}

.wayToCrt2 .WTC2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #FFCC00;
  transition: all ease-in-out 0.8s;
}

.wayToCrt2 .WTC2.active{
  width: 100%;
}

.indicatorCRT .stp1CRT {
  font-size: 1.5vw;
  background-color: #ffcc00;
  padding: 0.25vw 1vw;
  border-radius: 50%;
  border: 1px solid #8080803d;
  position: relative;
}

.indicatorCRT .stp1CRT span {
  position: absolute;
  bottom: -2.5vw;
  width: max-content;
  font-size: 0.5vw;
  right: -1.8vw;
}

.indicatorCRT .stp2CRT span {
  position: absolute;
  bottom: -2.5vw;
  width: max-content;
  font-size: 0.5vw;
  right: -1.8vw;
}

.indicatorCRT .stp2CRT {
  font-size: 1.5vw;
  background-color: #0000001f;
  padding: 0.25vw 1vw;
  border-radius: 50%;
  border: 1px solid #8080803d;
  position: relative;
}

.indicatorCRT .stp2CRT.selectedTab{
  background-color: #ffcc00;
}

.formForCLR1 {
  width: 65vw;
  margin: auto;
  display: flex;
  gap: 2vw;
  flex-direction: column;
  align-items: center;
}

.formForCLR1 .titleMain {
  width: 45vw;
  font-size: 4vw;
  text-align: center;
  margin-top: 8vw;
  line-height: 3.8vw;
}

/* //rgba(0, 0, 0, 0.64)  descrMain */

.formForCLR1 .descrMain{
  color: rgba(0, 0, 0, 0.64);
  width: 30vw;
  text-align: center;
  font-size: 1vw;
}

/* inputBoxesBox */

.formForCLR1 .inputBoxesBox {
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.formForCLR1 .inputBoxesBox input {
  padding: 0.5vw;
  outline: none;
  border: none;
  border: 1px solid #8080803d;
  font-size: 1.2vw;
  border-radius: 4px;
}


.formForCLR1 .inputBoxesBox .orgDescrrr {
  font-size: 1.6vw;
  margin-bottom: 0.5vw;
}

.formForCLR1 .inputBoxesBox .orgDescrrr textarea{
  height: 15vh;
  padding: 0.5vw;
  outline: none;
  border: none;
  border: 1px solid #8080803d;
  font-size: 1.2vw;
  resize: none;
  width: 100%;
  margin-top: 0.5vw;
  border-radius: 4px;

}

.formForCLR1 .inputBoxesBox .residenceitit {
  display: flex;
  flex-basis: 40%;
  gap: 10%;
}

.formForCLR1 .inputBoxesBox .residenceitit > *{
  flex: 0 0 45%;
  text-wrap: none !important;
}

.formForCLR1 .inputBoxesBox .orgDP{
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-top: 4vw;
}

.formForCLR1 .inputBoxesBox .orgDP .hehhehe {
  font-size: 2vw;
}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox {
  display: flex;
  gap: 1vw;

}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox  .bigGreyBox {
  width: 15vw;
  height: 15vw;
  background-color: rgba(0, 0, 0, 0.04);
  border: 2px dotted rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox  .bigGreyBox img{
  width: 100%;
  height: 100%;
}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox  .bigGreyBox .heeeod {
  font-size: 6vw;
  line-height: 5vw;
  color: rgba(0, 0, 0, 0.48);
}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox  .bigGreyBox .typerrroororo {
  font-size: 2vw;
  color: rgba(0, 0, 0, 0.48);
}

.formForCLR1 .inputBoxesBox .orgDP .figmaBox  .bigGreyBox input {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* visibility: hidden; */
  /* display: hidden; */
  /* display: none !important; */
  /* z-index: 22222; */
  opacity: 0;
  cursor: pointer;
}

.formForCLR1 .inputBoxesBox .linennnenenne {
  width: 100%;
  height: 1px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.formForCLR1 .inputBoxesBox .adminAccountInfo {
  display: flex;
  gap: 10%;
}

.formForCLR1 .inputBoxesBox .adminAccountInfo input {
  flex: 0 0 45%;
}


.SVPCLroom {
  background-color: black;
  color: white;
  padding: 0.75vw 1.5vw;
  max-width: fit-content;
  margin: auto;
  margin-top: 3vw;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 2vw;
}

#jaoasjoasjaojas {
  width: 2vw;
  min-height: 1vw;
  margin-top: 1vw;
}

.boxesOfFee {
  margin-left: 8vw;
  margin-right: 8vw;
  display: flex;
  justify-content: space-between;
}

.boxesOfFee .Box1 {
  width: 25vw;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 1vw 1.5vw;
  background-color: rgba(0, 0, 0, 0.06);
  margin-top: 3vw;
}

.boxesOfFee .saBox2 {
  background-color: rgba(247, 228, 156, 0.8);
  margin-top: 3vw;
}

.boxesOfFee .Box1 .monthB1{
  font-size: 2vw;
  margin-bottom: 1vw;
}

.boxesOfFee .Box1 .priceB1 {
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
}

.boxesOfFee .Box1 .descrB1 {
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  gap: 0.8vw;
}

.boxesOfFee .Box1 .descrB1 span:nth-child(1){
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.boxesOfFee .Box1 .descrB1 span:nth-child(2){
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.boxesOfFee .Box1 .descrB1 span {
  padding-bottom: 0.8vw;
}

.boxesOfFee .Box1 .subscriberButton {
  border: 1px solid black;
  padding: 0.8vw 1.5vw;
  font-size: 1.5vw;
  text-align: center;
  margin-top: 3vw;
} 

.errormessageCF {
  color: red;
  font-size: 1.3vw;
}

.headOffer {
  margin-top: 6vw;
  height: 30vh;
  background-color: #FBF3D0;
  display: flex;
  /* padding-top: 3vw; */
  align-items: center;
  padding-left: 4vw;
}

.headOffer img{
  width: 8vw;
  height: 8vw;
  border: 5px solid black;
  border-radius: 5px;
}

.headOffer .textoto {
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
}

.headOffer .textoto .welcText {
  font-size: 2vw;
}

.headOffer .textoto .Descrrrr {
  font-size: 1.3vw;
  color: rgba(0, 0, 0, 0.56);
}

.headOffer .buttonToHost {
  /* align-self: flex-end; */
  margin-left: auto;
  margin-right: 2vw;
  font-size: 2vw;
  background-color: #FC0;
  height: max-content;
  width: max-content;
  /* align-self: center; */
  padding: 1vw 2vw;
  border: 3px solid rgba(0, 0, 0, 0.36);
  cursor: pointer;
}

.startScheduleCF {
  font-size: 2.4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

}

.startScheduleCF span:nth-child(1){
  margin-bottom: 1vw;
}

.startScheduleCF span:nth-child(2){
  text-decoration: underline;
  cursor: pointer;
  
}

.session {
  width: 316px;
  height: 284px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.18);
  margin-bottom: 10px;
}

.session .immmggg {
  width: 316px;
  height: 178px;
}

.session .immmggg img{
  object-fit: cover;
  width: 316px;
  height: 178px;
}

.session .captionnn {
  display: flex;
  flex-direction: column;
  height: 106px;
  width: 316px;
  padding: 15px;
  background-color: #FBF9F1;
}

.headOffer .buttonToHost {
  /* align-self: flex-end; */
  margin-left: auto;
  margin-right: 2vw;
  font-size: 1.3vw;
  background-color: #FC0;
  height: max-content;
  width: max-content;
  /* align-self: center; */
  padding: 0.5vw 1vw;
  border: 3px solid rgba(0, 0, 0, 0.36);
  cursor: pointer;
}

.session .captionnn .titttle {
  font-size: 16px;
  margin-bottom: 5px;
}

.session .captionnn .authooorrr {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 5px;
}

.session .captionnn .classAndTime {
  display: flex;
  color: rgba(0, 0, 0, 0.56);
  font-size: 14px;
}

.session .captionnn .classAndTime .datyy {
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.24);
  margin-right: 10px;
}

.sessionGridClr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 75%;
  justify-content: center;
  /* justify-items: center;
  align-items: center;
  align-content: center; */

}

.headOffer {
  margin-top: 6vw;
  height: 30vh;
  background-color: #FBF9F1;
  display: flex;
  /* padding-top: 3vw; */
  align-items: center;
  padding-left: 4vw;
}

.CarouselllForHP {
  display: flex;
  gap: 2vw;
  width: 77vw;
  overflow: hidden;
}

.carousel1 .arrowDivHP2 {
  position: relative;
  z-index: 12222;
}

.carousel1 .arrowDivHP2 .arrowleftbl{
  position: absolute;
  left: -1.5vw;
  z-index: 2222;
}

.carousel1 .arrowDivHP2 .arrowrightbl{
  position: absolute;
  right: -78.5vw;
  z-index: 2222;
}

.ooaskaoskd {
  padding: 1vw 2vw;
  font-size: 2vw;
  color : black;
  background-color: #ffcc00;
  width: max-content;
  cursor: pointer;
  margin: auto;
  
}

.jonathanClassRoom{
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.72) !important;
}

.messageHub {
  display: flex;
  margin-top: 16vh;
  margin-bottom: 10vh;
  max-height: 100vh;
}

.panel {
  display: flex;
  flex-direction: column;
  width: 386px;
  border : 1px solid #495656f0;
  padding: 2vw;
  max-height: 100vh;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.newChatIcon {
  width: 39px;
  height: 39px;

}

.panel .titleDiv {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 43px;
  gap: 35px;
  /* padding-left: 1vw; */
  /* padding-right: 1vw; */
  padding-bottom: 26.5px;
  border-bottom: 1px solid;
}
.panel .titleDiv .title {
  font-size: 14px !important;
  /* width: max-content !important; */
  margin-right: 0 !important;
}

.selectedPanelOnTheChat {
  text-decoration: underline;
  color: #000 !important;
}

.panel .titleDiv .messageRequestsMode {
  color: #ffcc00;
  
  font-size: 14px;

}

.panel .nameDivOnTop .nameOfMesseger {
  font-size: 21px;
}

.panel .nameDivOnTop {
  display: flex;
  gap: 2vw;
  align-items: center;
  justify-content: space-between;
  /* gap: 120px; */
}

.messageColumnInCH {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
}

.messageDiv {
  display: flex;
  align-items: center;
  padding: 0.5vw;
}

.messageDiv .imageProfile img{
  width: 4vw;
  height: 4vw;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);;
}

.messageDiv .textProfileMe {
  display: flex;
  flex-direction: column;
}

.messageDiv .textProfileMe .lastText {
  display: flex;
  font-size: 1.1vw;
  gap: 0.5vw;
  align-items: center;
  /* justify-content: center; */
  color: grey;
  margin-left: 1vw;

}

.messageDiv .textProfileMe .nameProfile {
  font-size: 1.2vw;
  margin-left: 1vw;
}

.messageColumnInCH .selectedMessageDiv {
  background-color: #ffcc0061;
}

.aboluteNewMessageDiv {
  position : fixed;
  bottom : 0;
  top : 0;
  left : 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

.aboluteNewMessageDiv .exitMessageNediv {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #161616ad;
  z-index: 12; 
}

.aboluteNewMessageDiv .newMessageDivListing {
  position: fixed;
  background-color: white;
  /* border-radius: 20px; */
  border: 1px solid black;
  width: 45vw;
  height: 60vh;
  top: 20vh;
  left: 27.5vw;
  z-index: 13;
  padding-top: 34px

}

.titleOfAction{
  font-size: 1.6vw;
  text-align: center;
  margin-bottom: 2vw;
}

.newMessageDivListing .turnTheNameNewMess {
  display: flex;
  align-items: center;
  font-size: 1.2vw;
  gap: 1vw;
  padding-left: 1vw;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.newMessageDivListing .turnTheNameNewMess input {
  font-size: 1.2vw;
  border: none;
  outline: none;
  width: 35vw;
}

.newMessageCanSendOne {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  overflow-y: scroll;
  height: 40vh;
}

.newMessageCanSendOne .peopleIdd {
  display: flex;
  gap: 4vw;
  align-items: center;
  /* justify-content: space-between; */
  padding-left: 2vw;
  padding-right: 2vw;
}

.newMessageCanSendOne .peopleIdd .img img {
  width: 4vw;
  height: 4vw;
  object-fit: cover;
  border-radius: 50%;
}

.newMessageCanSendOne .peopleIdd {
  padding-top: 0.4vw;
  padding-bottom: 0.4vw;
}

.noChat{
  text-align: center;
  font-size: 2vw;
  color: #495656f0;
  margin-top: 4vw;
  margin-bottom: 4vw;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: 500;

}

.noMessageToD {
  text-align: center;
  font-size: 12px;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noMessageToD span {
  cursor: pointer;
  width: 121px;
  height: 37px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFC61C;
}

.RequestChatScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: -webkit-fill-available;
  /* background-color: #FBF9F1; */
  max-height: 75vh;
  position: relative;
}

.RequestChatScreen .head {
  display: flex;
  border: 2px solid white;
  box-shadow: 0 0 20px 8px white;
  height: 5vw;
  align-items: center;
  padding-left: 3vw;
  gap: 2vw;
}

.RequestChatScreen .head .imageOfRCS img {
  width: 4vw;
  height: 4vw;
  object-fit: cover;
  border-radius: 50%;
  
}

.RequestChatScreen .messagesss {
  position: absolute;
  bottom: 0;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 1vw;
}

.acceptButton {
  position: absolute;
  bottom: 0;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 1vw;
  background-color: #FC0;
  color: white;
  font-size: 1.5vw;
  text-align: center;
  padding: 1vw;
  border-radius: 20px;
  cursor: pointer;
}

.RequestChatScreen .messagesss input  {
  border: none;
  width: 100%;
  outline : none;
  padding: 1vw;
  /* border-radius: 25px; */
  border: 1px solid;
}

.messageList {
  display: flex;
  flex-direction: column;
  gap : 2vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  height: 50vh;
  overflow-y: scroll;

}

.receivedBubble {
  background-color: #D9D9D9;
  color : white;
  font-size: 1.5vw;
  max-width: 30%;
  padding: 0.8vw;
  margin-left: 0.5vw;
  text-align: left;
  border-radius: 25px;
}

.sentBubble {
  background-color:#FFC61C;
  color : black;
  font-size: 1.5vw;
  max-width: 30%;
  padding: 0.8vw;
  margin-right: 0.5vw;
  text-align: right;
  border-radius: 25px;
  align-self: self-end;
}