body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --white: #fff;
  --light: #e9f1f3;
  --info: #01829b;
  --grey: #f5f5f5;
  --secondary: #7cb4c5;
  --dark: #334a52;
  --black: #fff; */
  --yellow: #ffcc00;
}

.bgy {
  background-color: var(--yellow) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("./Fonts/Gilroy/Gilroy-Light.otf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("./Fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

.gm {
  font-family: Figtree-Medium !important;
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy ExtraBold"), local("Gilroy-ExtraBold"),
    url("./Fonts/Gilroy/gilroy-extrabold.otf") format("truetype");
}

@font-face {
  font-family: "Balham";
  src: local("Balham"), local("Balham"),
    url("./Fonts/Gilroy/Balham.otf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("./Fonts/Gilroy/Gotham-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy Semi Bold"), local("Gilroy-SemiBold"),
    url("./Fonts/Gilroy/Radomir\ Tinkov\ -\ Gilroy-SemiBold.otf") format("truetype");
}

.skeleton {
  animation: skeleton-loader 1s linear infinite alternate;
}

.skeleton-slider {
  display: block !important;
  width: 100% !important;
}

.slideimg {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover !important;

  /* min-height: clamp(300px, 50vh, 800px); */
  max-height: 70vh !important;
  /* background-color shows in the reserved space before image loads */
  background-color: hsl(200, 20%, 75%);
  /* Make background pulse so it looks kinda load-y */
  animation: skeleton-loader 0.8s infinite alternate;
}

.skeleton-text {
  width: 30%;
  height: 0.9rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-smalltext {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-smalltext:last-child {
  margin-bottom: 0.25rem;
  width: 80%;
}

.skeleton-box {
  width: 100%;
  height: 2rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-id {
  width: 60%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

@keyframes skeleton-loader {
  0% {
    background-color: hsl(200, 20%, 75%);
  }
  50% {
    background-color: hsl(200, 20%, 85%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* .fn {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
} */

.boxshadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.zoom2 {
  height: 425px !important;
  overflow: hidden !important;
  position: absolute !important;
  border-radius: 15px !important;
  transition: 0s !important;
}

.zoom {
  height: 250px !important;
  overflow-y: hidden !important;
  /* position: absolute !important; */
  border-radius: 10px !important;
  transition: 0s all !important;
  transition-duration: 0.3s;
  transition-delay: 2.3s;
  transition-property: all;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 0s;
  background-color: white;
}

.zoom:hover {
  /* position: absolute !important; */
  /* margin-top: -30px  !important; */
  animation-delay: .9s  !important;
  transition-delay: .9s !important;
  /* height: 460px !important; */
  height: 365px !important;
  /* transition: 0.3s all !important; */
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: hsla(0, 100%, 100%, 0.16); */
  background-color: white;
  box-shadow: 0px 4px 8px 3px hsla(0, 0%, 0%, 0.14),
    0px 9px 46px 8px hsla(0, 0%, 0%, 0.12),
    0px 11px 15px -7px hsla(0, 0%, 0%, 0.2);
  border-radius: 10px !important;
  z-index: 999 !important;

}

.zhoom {
  height: 325px !important;
  overflow: hidden !important;
  position: absolute !important;
  border-radius: 15px !important;
  transition: 0s all !important;
  transition-duration: 0.3s;
  transition-delay: 2.3s;
  transition-property: all;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.zhoom:hover {
  z-index: 100 !important;
  margin-top: -50px !important;
  animation-delay: 3s;
  transition-delay: 3s !important;
  height: fit-content !important;
  transform: scale(1.1);
  transition-delay: 3s !important;
  transition: transform 0.3s;
  background-color: hsla(0, 100%, 100%, 0.16);
  box-shadow: 0px 24px 38px 3px hsla(0, 0%, 0%, 0.14),
    0px 9px 46px 8px hsla(0, 0%, 0%, 0.12),
    0px 11px 15px -7px hsla(0, 0%, 0%, 0.2);
  border-radius: 15px !important;
}

.ic {
  width: 50px;
  height: 50px;
  /* width: 100% !important; */
  /* fill the container, preserving aspect ratio, and cropping to fit */
  /* background-size: cover; */
  object-fit: cover;
  /* center the image vertically and horizontally */
  background-position: top center;
  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.ic2 {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-position: top center;
  border-radius: 50%;
}

.ic3 {
  width: 100% !important;

  object-fit: cover;

  background-position: top center;

  border-radius: 50%;
}

.video {
  position: relative !important;
  height: fit-content !important;
  width: 100% !important;
}

.video button {
  position: absolute !important;
  display: block;
  background: url(http://www.slatecube.com/images/play-btn.png);
  height: 50%;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 100%;
  background-repeat: no-repeat;
  border: none;
  opacity: 0.5;
  cursor: pointer;
}

.read-more-button {
  cursor: pointer !important;
}


@font-face {
  font-family: "Figtree-Light";
  src: local("Figtree Light"), local("Figtree-Light"),
    url("./Fonts/Figtree/Figtree-Light.ttf") format("truetype");
  /* font-weight: bold; */
}


@font-face {
  font-family: "Figtree-Regular";
  src: local("Figtree Regular"), local("Figtree-Regular"),
    url("./Fonts/Figtree/Figtree-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Figtree-Medium";
  src: local("Figtree Medium"), local("Figtree-Medium"),
    url("./Fonts/Figtree/Figtree-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Figtree-ExtraBold";
  src: local("Figtree ExtraBold"), local("Figtree-ExtraBold"),
    url("./Fonts/Figtree/Figtree-ExtraBold.ttf") format("truetype");
}

/* @font-face {
  font-family: "Balham";
  src: local("Balham"), local("Balham"),
    url("./Fonts/Gilroy/Balham.otf") format("truetype");
} */

@font-face {
  font-family: "Figtree-Bold";
  src: local("Figtree Bold"), local("Figtree-Bold"),
    url("./Fonts/Figtree/Figtree-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: local("Figtree Semi Bold"), local("Figtree-SemiBold"),
    url("./Fonts/Figtree/Figtree-SemiBold.ttf") format("truetype");
}

.fbfont { 
  font-family: Figtree-Bold !important;
}

.frfont { 
  font-family: Figtree-Regular !important;
}

.fsbfont {
  font-family: Figtree-SemiBold !important;
}

.febfont {
  font-family: Figtree-ExtraBold !important;
}

.fmfont {
  font-family: Figtree-Medium !important;
}

.flfont {
  font-family: Figtree-Light !important;
}